const state = () => ({
    customerList: [],
    form: {
        modal_form_customer: false,
        mode: 'create', // create, update
        id: '',
        full_name: '',
        tax_no: '',
        phone: '',
        email: '',
        address: '',
    },
    error_msg: {
        full_name: '',
        tax_no: '',
        phone: '',
        email: '',
    }
})
const getters = {}

const actions = {
}

const mutations = {
    setCustomers(state, value) {
        state.customerList = value
    },
    setModalFormCustomers(state, {mode, customer}) {
        state.form.modal_form_customer = true;
        state.form.mode = mode;
        state.form.id = mode === 'update' ? customer.id : '';
        state.form.full_name = mode === 'update' ? customer.full_name : '';
        state.form.tax_no = mode === 'update' ? customer.tax_no : '';
        state.form.customer_level_id = mode === 'update' ? customer.customer_level_id : null;
        state.form.phone = mode === 'update' ? customer.phone : '';
        state.form.email = mode === 'update' ? customer.email : '';
        state.form.address = mode === 'update' ? customer.address : '';
    },
    setFormName(state, value) {
        state.form.full_name = value;
    },
    setFormTaxNo(state, value) {
        state.form.tax_no = value;
    },
    setFormPhone(state, value) {
        state.form.phone = value;
    },
    setFormEmail(state, value) {
        state.form.email = value;
    },
    setFormCustomerLevelId(state, value) {
        state.form.customer_level_id = value;
    },
    setFormExpiredAt(state, value) {
        state.form.expired_at = value;
    },
    setFormAddress(state, value) {
        state.form.address = value;
    },
    setClearForm(state) {
        state.form = {
            ...state.form,
            full_name: '',
            tax_no: '',
            phone: '',
            email: '',
            address: '',
        };
    },
    setModalFormCustomersFalse(state) {
        state.form.modal_form_customer = false;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}