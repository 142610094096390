<template>
	<div class="flex flex-col w-full h-max px-5 mx-2 bg-white rounded-2xl pb-2">
		<div class="flex items-center justify-between mt-5 mb-2">
			<h2 class="my-6 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
				Customer ({{ total }})
			</h2>
			<div class="flex justify-center flex-1 lg:mr-32">
				<div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
					<div class="absolute inset-y-0 flex items-center pl-2">
						<i class="fas fa-search"></i>
					</div>
					<form @submit.prevent="submitSearchForm">
						<input
							v-model="keyword"
							class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md"
							type="text"
							placeholder="Search"
							aria-label="Search"
						/>
					</form>
				</div>
				<div>
					<button
						@click="submitSearchForm"
						class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple"
					>
						<i class="fas fa-search mr-2"></i>
						<span>Search</span>
					</button>
				</div>
				<div>
					<button
						@click="resetSearchForm"
						class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-red-500 transition-colors duration-150 border-red-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-purple"
					>
						<i class="fas fa-sync mr-2"></i>
						<span>Clear</span>
					</button>
				</div>
			</div>
			<button
				@click="onOpenModalForm('create', {})"
				class="flex items-center justify-between px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple"
			>
				<span class="mr-2 font-bold">+</span>
				<span>Create</span>
			</button>
		</div>

		<div class="w-full overflow-hidden rounded-lg shadow-sm">
			<div class="h-full overflow-y-auto">
				<table class="w-full whitespace-no-wrap">
					<thead>
						<tr
							class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800"
						>
							<th class="px-4 py-3">#</th>
							<th class="px-4 py-3">Name</th>
							<th class="px-4 py-3">Tax</th>
							<th class="px-4 py-3">Phone</th>
							<th class="px-4 py-3">Address</th>
							<th class="px-4 py-3"></th>
						</tr>
					</thead>
					<tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
						<tr
							v-for="(customer, index) in customers"
							:key="customer.id"
							class="text-gray-700 dark:text-gray-400 hover:bg-blue-100"
						>
							<td class="px-4 py-3 text-sm">{{ (current_page - 1) * per_page + (index + 1) }}</td>
							<td class="px-4 py-3">
								<div class="flex items-center text-sm">
									<div>
										<p class="font-semibold">{{ customer.full_name }}</p>
									</div>
								</div>
							</td>
							<td class="px-4 py-3 text-sm">{{ customer.tax_no }}</td>
							<td class="px-4 py-3 text-sm">{{ customer.phone }}</td>
							<td class="px-4 py-3 text-sm">{{ customer.address }}</td>
							<td class="px-4 py-3 text-sm text-center">
								<button
									@click="onOpenModalForm('update', customer)"
									class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="far fa-edit"></i>
								</button>
								<button
									@click="deleteCustomer(customer.id)"
									class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<VueTailwindPagination
			:current="current_page"
			:total="total"
			:per-page="per_page"
			@page-changed="onPageClick($event)"
		/>
		<!-- Page -->

		<ModalForm v-if="modal_form_customer" @saveCustomer="fetchCustomer" />
	</div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import ModalForm from '@/components/customer/ModalForm.vue';
import api from '@/services/auth';
import { useSweetalertTopEnd } from '@/js/sweetalert';
import '@ocrv/vue-tailwind-pagination/dist/style.css';
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination';
import store from '@/store/index.js';

export default {
	name: 'Customer',
	components: {
		ModalForm,
		VueTailwindPagination,
	},
	setup() {
		const customers = ref([]);
		const keyword = ref('');
		const total = ref(0);
		const current_page = ref(1);
		const per_page = ref(0);
		const modal_form_customer = computed(() => store.state.customers.form.modal_form_customer);
		const getCustomers = (page, keyword_param) => {
			api.get(`api/customers?page=${page}&keyword=${keyword_param}`)
				.then(response => {
					console.log(response.data);
					let success_response = response.data;
					customers.value = success_response.data.data;
					total.value = success_response.data.total;
					current_page.value = success_response.data.current_page;
					per_page.value = success_response.data.per_page;
				})
				.catch(error => {
					let error_response = error.response.data;
					useSweetalertTopEnd(1500, 'error', error_response.message);
				});
		};

		onMounted(() => {
			getCustomers(current_page.value, keyword.value);
		});

		function onPageClick(event) {
			current_page.value = event;

			getCustomers(current_page.value, keyword.value);
		}
		const onOpenModalForm = (mode, customer) => {
			store.commit('customers/setModalFormCustomers', { mode, customer });
		};

		function submitSearchForm() {
			getCustomers(1, keyword.value);
		}
		function resetSearchForm() {
			keyword.value = '';
			getCustomers(1, keyword.value);
		}

		function fetchCustomer(mode) {
			if (mode == 'create') {
				getCustomers(1, '');
			} else if (mode == 'update') {
				getCustomers(current_page.value, keyword.value);
			}
		}

		return {
			customers,
			keyword,
			getCustomers,
			total,
			current_page,
			per_page,
			onPageClick,
			modal_form_customer,
			fetchCustomer,
			onOpenModalForm,
			submitSearchForm,
			resetSearchForm,
		};
	},
};
</script>
