<template>
	<!-- store menu -->
	<div class="flex flex-col h-full w-full">
		<form @submit.prevent="submitSearchForm">
			<div class="flex px-2 flex-row relative">
				<button
					type="submit"
					class="absolute left-5 top-3 px-2 py-2 rounded-full h-10 w-10 flex items-center justify-center bg-green-500 text-white"
				>
					<i class="fas fa-search"></i>
				</button>
				<button
					v-if="barcode"
					@click="resetSearchForm"
					type="button"
					class="absolute left-5 top-3 px-2 py-2 rounded-full h-10 w-10 flex items-center justify-center bg-yellow-500 text-white hover:bg-yellow-600"
				>
					<i class="fas fa-undo"></i>
				</button>
				<input
					v-model="keyword"
					ref="input_search"
					type="text"
					:class="barcode ? 'bg-yellow-100 border border-red-400' : 'bg-white'"
					class="rounded-3xl shadow text-lg full w-full h-16 py-4 pl-16 transition-shadow focus:shadow-md focus:outline-none"
					:placeholder="barcode ? 'Scan Serial Number' : 'Scan Barcode / Serial Number'"
					autofocus
				/>
			</div>
		</form>
		<div class="h-full overflow-hidden mt-4">
			<Products :products="products" :addToCart="addToCart" :keyword="keyword" />
		</div>
	</div>
	<!-- end of store menu -->
	<!-- right sidebar -->
	<div class="w-9/12 flex flex-col h-full pr-4 pl-2 ">
		<Cart
			:order_no="order_no"
			:carts="carts"
			:clearCart="clearCart"
			:addDiscount="addDiscount"
			:subQty="subQty"
			:addQty="addQty"
			:netPrice="netPrice"
			:sumPrice="sumPrice"
			:sumDiscount="sumDiscount"
			:save="save"
			:selectedReceiptOption="selectedReceiptOption"
			:onReceiptTypeChange="handleReceiptTypeChange"
		/>
	</div>
	<!--Modal Shelf-->
	<div v-if="modal_shelf" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
		<div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

		<div class="modal-container bg-white lg:w-1/4 md:w-1/2 w-1/3 mx-auto rounded shadow-lg z-50">
			<!-- Add margin if you want to see some of the overlay behind the modal-->
			<div class="modal-content py-4 text-left px-6">
				<!--Title-->
				<div class="flex justify-between items-center pb-3">
					<p class="font-bold">Please select shelf</p>
					<div @click="modal_shelf = false" class="modal-close cursor-pointer z-50">
						<i class="fas fa-times"></i>
					</div>
				</div>
				<!--Body-->
				<div class="flex flex-row  mb-2">
					<Multiselect v-model="shelf_id" :options="shelf_options" searchable @clear="shelf_id = ''" />
				</div>
				<!--Footer-->
				<div class="flex justify-end pt-2">
					<button
						@click="modal_shelf = false"
						class="bg-red-400 hover:bg-red-600 p-3 rounded-lg text-white font-bold mr-2"
					>
						Cancel
					</button>
					<button
						@click="saveBarcode"
						class="bg-green-400 hover:bg-green-600 p-3 rounded-lg text-white font-bold mr-2"
					>
						Select
					</button>
				</div>
			</div>
		</div>
	</div>
	<!--Modal Shelf-->

	<!--Modal Receipt-->
	<div
		v-if="modal_receipt"
		class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center overflow-hidden"
		@contextmenu="stop_click_right($event)"
	>
		<div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

		<div
			class="modal-container bg-white lg:w-1/3 w-1/2 mx-auto rounded-2xl shadow-lg z-50 max-h-full overflow-y-auto"
		>
			<div class="">
				<div
					@click="modal_receipt = false"
					class="modal-close cursor-pointer z-50 float-right mr-3 mt-2 text-yellow-600"
				>
					<button
						class="border bg-yellow-400 text-white px-3 py-1 text-xl rounded-2xl hover:bg-yellow-500 focus:outline-none"
					>
						<i class="fas fa-edit"></i>
					</button>
				</div>
				<!--Body-->
				<Receipt :order_no="order_no"  />

				<div class="p-4 w-full flex justify-center">
					<button
						@click="printReceipt"
						class="text-white bg-green-400 hover:bg-green-600 text-lg py-2 rounded-2xl w-full focus:outline-none mx-2"
					>
						<i class="fas fa-print mr-2"></i>Receipt
					</button>
					<button
						v-if="selectedReceiptOption === 'Tax'"
						@click="openModalCustomer"
						class="text-yellow-600 text-lg py-2 rounded-2xl w-1/2 focus:outline-none border border-yellow-600 mx-2 hover:bg-yellow-600 hover:text-white"
					>
						<i class="fas fa-file-invoice-dollar mr-2"></i>TAX
					</button>
				</div>
			</div>
		</div>
	</div>

	<!--Modal discount-->
	<div v-if="modal_discount" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
		<div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

		<div class="modal-container bg-white lg:w-1/3 md:w-1/2 w-1/3 mx-auto rounded shadow-lg z-50">
			<!-- Add margin if you want to see some of the overlay behind the modal-->
			<div class="modal-content py-4 text-left px-6">
				<!--Title-->
				<div class="flex justify-between items-center pb-3">
					<p class="font-bold">Price and Discount</p>
					<div @click="modal_discount = false" class="modal-close cursor-pointer z-50">
						<i class="fas fa-times"></i>
					</div>
				</div>
				<hr />
				<!--Body-->
				<div class="flex w-full overflow-auto my-3">
					<img :src="cart_edit_price.photo" alt="" class="rounded-lg h-20 w-20 bg-white shadow mx-3" />
					<div class="flex-grow">
						<h5 class="text-sm">{{ cart_edit_price.name }}</h5>
						<p class="text-xs block" v-if="cart_edit_price.serial_status">
							S/N: {{ cart_edit_price.serial }}
						</p>
						<p class="text-xs block" v-if="!cart_edit_price.serial_status">
							Shelf: {{ cart_edit_price.shelved_address }}
						</p>
						<p class="text-xs block">
							<span>{{ formatNumber(cart_edit_price.price) }} Baht/Psc.</span>
						</p>
						<p class="text-xs block">
							<span>{{ formatNumber(cart_edit_price.quantity) }} Psc.</span>
						</p>
						<p class="text-xs block">
							<span v-if="cart_edit_price.discount_baht == 0"
								>Total {{ formatNumber(cart_edit_price.price * cart_edit_price.quantity) }} Baht</span
							>
							<span v-if="cart_edit_price.discount_baht > 0"
								>Total
								{{
									formatNumber(
										cart_edit_price.price * cart_edit_price.quantity - cart_edit_price.discount_baht
									)
								}}
								( {{ formatNumber(cart_edit_price.price * cart_edit_price.quantity) }} ) Baht</span
							>
						</p>
					</div>
				</div>
				<div class="flex flex-wrap my-3">
					<div class="w-full px-3">
						<label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="price_edit">
							Price (Baht/Psc)
						</label>
						<input
							@input="discountBathToPercentCart"
							v-model="cart_edit_price.price"
							class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
							id="price_edit"
							type="number"
							placeholder="Price / Pcs."
						/>
						<p class="text-red-500 text-xs italic"></p>
					</div>
				</div>
				<div class="flex flex-wrap mb-3">
					<div class="w-full px-3">
						<label
							class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							for="discount_percent_edit"
						>
							Discount (%)
						</label>
						<input
							@input="discountPercentToBathCart"
							v-model="cart_edit_price.discount_percent"
							class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
							id="discount_percent_edit"
							type="number"
							placeholder="Price / Pcs."
						/>
						<p class="text-red-500 text-xs italic"></p>
					</div>
				</div>
				<div class="flex flex-wrap mb-3">
					<div class="w-full px-3">
						<label
							class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							for="discount_baht"
						>
							Discount (฿)
						</label>
						<input
							@input="discountBathToPercentCart"
							v-model="cart_edit_price.discount_baht"
							class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
							id="discount_baht"
							type="number"
							placeholder="Price / Pcs."
						/>
						<p class="text-red-500 text-xs italic"></p>
					</div>
				</div>
				<!--Footer-->
				<div class="flex justify-end pt-2">
					<button
						@click="modal_discount = false"
						class="bg-red-400 hover:bg-red-600 p-3 rounded-lg text-white font-bold mr-2"
					>
						Close
					</button>
					<button
						@click="saveDiscount"
						class="bg-green-400 hover:bg-green-600 p-3 rounded-lg text-white font-bold mr-2"
					>
						Add
					</button>
				</div>
			</div>
		</div>
	</div>
	<!--Modal discount-->
	<!--Modal payment_type-->
	<div v-if="modal_payment_type" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
		<div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

		<div class="modal-container bg-white w-auto mx-auto rounded shadow-lg z-50">
			<!-- Add margin if you want to see some of the overlay behind the modal-->
			<div class="modal-content py-4 text-left px-6">
				<!--Title-->
				<div class="flex justify-between items-center pb-3">
					<div class="flex gap-3 items-center text-sm">
						<p class="font-bold text-lg">Payments</p>
						<div class="w-auto border-none p-2 bg-green-400 rounded-lg">
							<span>Balance / Total : </span>
							<span> {{ formatNumber(balanceSum) }} / {{ formatNumber(netPrice) }} </span>
						</div>
					</div>
				</div>
				<hr />
				<!--Body-->
				<div class="flex flex-wrap my-3">
					<div class="w-full px-3">
						<span v-for="paymentType in paymentTypeLists" :key="paymentType.id">
							<input
								@click="select_payment_type"
								type="button"
								:value="paymentType.message"
								:id="paymentType.label"
								class="text-blue-600 bg-white border-2 border-blue-600 focus:outline-none focus:bg-blue-100 focus:text-blue-800 hover:bg-blue-200 cursor-pointer focus:ring-2 focus:ring-blue-500 font-semibold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
							/>
						</span>
					</div>
				</div>
				<!-- showData -->
				<div class="mb-2">
					<div class="text-xs p-1 pl-2 text-center bg-gray-300">Payment records</div>
					<div v-if="array_payment.length === 0" class="text-sm text-center">No payment record</div>

					<div v-for="pay in array_payment" :key="pay.id" class="flex flex-col">
						<div class="flex gap-x-2 py-2 items-center justify-between">
							<div class="ml-2 text-sm w-92">
								<div class="flex items-center">
									<span> {{ pay.payment_type }} :</span>
									<span>&nbsp;{{ formatNumber(pay.amount) }} Baht </span>
									<span v-if="pay.payment_type != pay.payment_option"
										>&nbsp;({{ pay.payment_option }})</span
									>
								</div>
								<div class="flex items-center gap-10 flex-wrap">
									<div v-if="pay.payment_note" class="flex items-center">
										<div class="flex items-center">
											<span class="ml-2 text-sm">Note :</span>
											<span class="text-sm"> &nbsp; {{ pay.payment_note }}</span>
										</div>
									</div>
									<div v-if="pay.attach_file_url" class="flex items-center">
										<span class="ml-2 text-sm">Attach file :</span>
										<a :href="pay.attach_file_url" :target="pay.attach_file_url">
											<img
												:src="pay.attach_file_url"
												alt="attach_file_url"
												width="30"
												class="cursor-pointer border-double border-2"
											/>
										</a>
									</div>
								</div>
							</div>

							<div class="">
								<div
									class="mr-4 px-2 py-1 rounded-md text-white border-white border border-transparent bg-red-500 focus:outline-none text-md hover:bg-red-700 cursor-pointer"
									@click="delPayment(pay)"
								>
									<i class="far fa-trash-alt"></i>
								</div>
							</div>
						</div>

						<hr />
					</div>
				</div>

				<div v-if="payment.payment_type.length !== 0" class="border border-black rounded-lg shadow-lg">
					<div class="ml-3 text-sm p-2">
						<div>
							<div>
								<div class="flex items-center justify-between">
									<div v-if="paymentTypeLists.filter(obj => obj.option.length !== 0)">
										<span
											v-for="option in paymentTypeLists.find(
												item => item.message === payment.payment_type
											)?.option"
											:key="option.label"
										>
											<input
												type="button"
												:value="option.message"
												:id="option.label"
												class="text-blue-600 bg-white border-2 border-blue-600 focus:outline-none focus:bg-blue-100 focus:text-blue-800 hover:bg-blue-200 focus:ring-2 focus:ring-blue-500 font-semibold rounded-lg text-xs px-5 py-1.5 mr-2"
												@click="selectPaymentOption"
											/>
										</span>
									</div>

									<div v-if="!payment.payment_option" class="border-none w-7 h-7 rounded-full mr-2">
										<button
											class="bg-red-500 rounded-full w-full h-full flex justify-center items-center"
											@click="clearDataPayment"
										>
											<svg
												width="13"
												height="13"
												viewBox="0 0 13 13"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M8.6541 6.49993L11.8847 3.26937C12.2811 2.87293 12.2811 2.23018 11.8847 1.83342L11.1667 1.11544C10.7703 0.719006 10.1275 0.719006 9.73074 1.11544L6.50018 4.34601L3.26961 1.11544C2.87318 0.719006 2.23042 0.719006 1.83366 1.11544L1.11569 1.83342C0.71925 2.22985 0.71925 2.87261 1.11569 3.26937L4.34625 6.49993L1.11569 9.7305C0.71925 10.1269 0.71925 10.7697 1.11569 11.1664L1.83366 11.8844C2.2301 12.2809 2.87318 12.2809 3.26961 11.8844L6.50018 8.65386L9.73074 11.8844C10.1272 12.2809 10.7703 12.2809 11.1667 11.8844L11.8847 11.1664C12.2811 10.77 12.2811 10.1273 11.8847 9.7305L8.6541 6.49993Z"
													fill="white"
												/>
											</svg>
										</button>
									</div>
								</div>

								<div v-if="payment.payment_option">
									<div class="flex items-center mt-3 justify-between">
										<div class="">
											<span class=""> {{ payment.payment_option }} : </span>
											<input
												type="number"
												v-model="payment.amount"
												class="border border-black rounded-lg ml-1 h-8 text-right"
											/>
											<span class="ml-2">Baht</span>
											<button
												class="border-none ml-2 rounded-md px-2 py-1 bg-blue-300 hover:bg-blue-400"
												@click="fillRemain"
											>
												Fill remain
											</button>
										</div>
										<label class="flex ml-4 items-center">
											<svg
												width="20"
												height="20"
												class="mr-1"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 512 512"
											>
												<path
													d="M396.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"
												/>
											</svg>
											<input
												type="file"
												class="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
												name=""
												accept="image/*"
												@change="onFileChange"
											/>
										</label>

										<div class="flex">
											<div class="border-none w-7 h-7 rounded-full mr-2">
												<button
													class="bg-red-500 rounded-full w-full h-full flex justify-center items-center"
													@click="clearDataPayment"
												>
													<svg
														width="13"
														height="13"
														viewBox="0 0 13 13"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M8.6541 6.49993L11.8847 3.26937C12.2811 2.87293 12.2811 2.23018 11.8847 1.83342L11.1667 1.11544C10.7703 0.719006 10.1275 0.719006 9.73074 1.11544L6.50018 4.34601L3.26961 1.11544C2.87318 0.719006 2.23042 0.719006 1.83366 1.11544L1.11569 1.83342C0.71925 2.22985 0.71925 2.87261 1.11569 3.26937L4.34625 6.49993L1.11569 9.7305C0.71925 10.1269 0.71925 10.7697 1.11569 11.1664L1.83366 11.8844C2.2301 12.2809 2.87318 12.2809 3.26961 11.8844L6.50018 8.65386L9.73074 11.8844C10.1272 12.2809 10.7703 12.2809 11.1667 11.8844L11.8847 11.1664C12.2811 10.77 12.2811 10.1273 11.8847 9.7305L8.6541 6.49993Z"
															fill="white"
														/>
													</svg>
												</button>
											</div>
											<div class="w-7 h-7 rounded-full mr-2 border-none">
												<button
													class="border-none bg-green-500 w-full h-full rounded-full flex justify-center items-center"
													@click="addPayment()"
												>
													<svg
														width="13"
														height="10"
														viewBox="0 0 13 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M4.49555 9.26333L0.433051 5.20083C0.188983 4.95676 0.188983 4.56103 0.433051 4.31694L1.31691 3.43305C1.56098 3.18896 1.95673 3.18896 2.2008 3.43305L4.9375 6.16973L10.7992 0.308051C11.0433 0.0639831 11.439 0.0639831 11.6831 0.308051L12.5669 1.19194C12.811 1.43601 12.811 1.83173 12.5669 2.07583L5.37944 9.26336C5.13535 9.50743 4.73962 9.50743 4.49555 9.26333Z"
															fill="white"
														/>
													</svg>
												</button>
											</div>
										</div>
									</div>

									<div
										v-if="
											paymentTypeLists.find(item => item.message == payment.payment_type)
												? paymentTypeLists.find(item => item.message == payment.payment_type)
														.is_order_no
												: false
										"
										class="flex flex-wrap my-3"
									>
										<div class="w-full">
											<label
												class="block tracking-wide text-gray-700 text-sm  mb-2"
												for="online_order_no"
											>
												Order no <span class="text-red-600">*</span>
											</label>
											<input
												v-model="payment.online_order_no"
												type="text"
												name="online_order_no"
												id="online_order_no"
												cols="20"
												rows="5"
												class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
											/>
										</div>
									</div>

									<div class="w-full mt-1">
										<label
											class="block tracking-wide text-gray-700 text-sm mb-2"
											for="payment_note"
										>
											Note
										</label>
										<textarea
											v-model="payment.payment_note"
											name="payment_note"
											id="payment_note"
											cols="20"
											rows="5"
											class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
										>
										</textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--Footer-->

				<div v-if="payment.payment_type == ''" class="flex justify-end pt-2">
					<button
						@click="modal_payment_type = false"
						class="bg-red-400 hover:bg-red-600 p-3 rounded-lg text-white font-bold mr-2"
					>
						Close
					</button>
					<button
						@click="save(2, 'savePayment')"
						class="bg-green-400 hover:bg-green-600 p-3 rounded-lg text-white font-bold mr-2"
					>
						Checkout
					</button>
				</div>
			</div>
		</div>
	</div>
	<!--Modal payment_type-->

	<!--Modal Tax-->
	<span v-if="modal_form_tax">
		<TaxModal
			:modal_form_tax_props="modal_form_tax"
			:order_no_props="order_no"
			@closeModalTax="modal_form_tax = false"
			@saveTax="printTaxInvoice"
		/>
	</span>
	<!--Modal Tax-->
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import Swal from 'sweetalert2';
import Receipt from '@/components/Receipt.vue';
import TaxModal from '@/components/TaxModal.vue';
import api from '@/services/auth';
import { useSweetalertTopEnd, useSweetalertError } from '@/js/sweetalert';
import { useFormatNumber, formatNumberTwoDecimal } from '@/js/format';
import { useGetOrderByOrderNo, alert_is_order_management } from '@/js/pos_order';
import Cart from '@/components/pos/Cart.vue';
import Products from '@/components/pos/Products.vue';
import Multiselect from '@vueform/multiselect';

export default {
	name: 'Pos',
	components: {
		Cart,
		Products,
		Receipt,
		TaxModal,
		Multiselect,
	},
	setup() {
		const keyword = ref('');
		const keyword_result = ref('');
		const products = ref([]);
		const discount_bath = ref(0);
		const discount_percent = ref(0);
		const carts = ref([]);
		const modal_shelf = ref(false);
		const shelf_options = ref([]);
		const modal_receipt = ref(false);
		const modal_discount = ref(false);
		const modal_payment_type = ref(false);
		const order_no = ref('');
		const router = useRouter();
		const route = useRoute();
		const formatNumber = useFormatNumber;
		const customer_id = ref(0);
		const pos_order_status_id = ref();
		const { order, line_items, getOrderByOrderNo } = useGetOrderByOrderNo();
		const modal_form_tax = ref(false);
		const pos_machine_id = localStorage.getItem('pos_machine_id');
		const warehouse_location_id = localStorage.getItem('warehouse_location_id');
		const barcode = ref('');
		const serial = ref('');
		const shelf_id = ref('');
		const shelf_barcode = ref('');
		const cart_edit_price = ref('');
		const is_order_management = ref(null);
		const array_payment = ref([]);
		const payment = ref({
			id: null,
			payment_type: '',
			payment_option: '',
			payment_note: '',
			online_order_no: '',
			amount: '',
			attach_file: {
				file_name: '',
				mime_type: '',
				url: '',
			},
			attach_file_url: '',
		});
		const input_search = ref('');
		const store = useStore();
		const paymentTypeLists = computed(() => store.state.payments.paymentTypeLists);
		const selectedReceiptOption = ref('Tax');

		const getProducts = warehouse_location_id => {
			api.get(`api/pos_products`, {
				params: {
					warehouse_location_id,
					keyword: btoa(keyword.value),
					barcode: btoa(barcode.value),
					serial: btoa(serial.value),
					group_price_id: process.env.VUE_APP_ERP_GROUP_PRICE_ID,
				},
			})
				.then(response => {
					let success_response = response.data;
					if (success_response.status == 0) {
						if (success_response.message == 'cart') {
							products.value = success_response.data;
							addToCart(products.value[0]);
							resetSearchForm();
						}
						if (success_response.message == 'display') {
							products.value = success_response.data;
							barcode.value = keyword.value;
						}
						if (success_response.message == 'shelf') {
							// alert select shelf
							shelf_options.value = [];
							success_response.data.forEach(function(item) {
								let arr = {
									value: item.shelf_id,
									label: `${item.shelved_address} : ${item.quantity} Pcs.`,
								};
								shelf_options.value.push(arr);
							});
							modal_shelf.value = true;
							shelf_barcode.value = keyword.value;
						}
					}
					if (success_response.status == 1) {
						if (barcode.value == '') {
							resetSearchForm();
						}
						Swal.fire({
							icon: 'error',
							text: success_response.message,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'OK',
						});
					}
					keyword.value = '';
				})
				.catch(error => {
					let error_response = error.response.data;
					useSweetalertTopEnd(1500, 'error', error_response.message);
				});
		};

		onMounted(() => {
			getProducts(warehouse_location_id);
			if (route.query.order_no_query) {
				order_no.value = route.query.order_no_query;
				getOrderByOrderNo(order_no.value).then(() => {
					discount_bath.value = order.value.discount_bath;
					selectedReceiptOption.value = order.value.bill_type;
					discount_percent.value = order.value.discount_percent;
					is_order_management.value = order.value.is_order_management;
					carts.value = [...line_items.value];
					formatArrayPayment(order.value.pos_order_payments);
				});
			}
		});

		function saveBarcode() {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			if (shelf_id.value == '' || shelf_id.value == null) {
				alert('Please select shelf');
				return false;
			}
			api.get(`api/pos_shelf_products`, {
				params: {
					shelf_id: shelf_id.value,
					barcode: shelf_barcode.value,
					group_price_id: process.env.VUE_APP_ERP_GROUP_PRICE_ID,
				},
			})
				.then(response => {
					let success_response = response.data;
					products.value = success_response.data;
					if (success_response.status == 0) {
						if (success_response.message == 'cart') {
							addToCart(products.value[0]);
							resetSearchForm();
						}
					}
					modal_shelf.value = false;
					shelf_id.value = '';
					shelf_barcode.value = '';
				})
				.catch(error => {
					let error_response = error.response.data;
					useSweetalertTopEnd(1500, 'error', error_response.message);
				});
		}
		function submitSearchForm() {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			if (!keyword.value) {
				return false;
			}
			if (barcode.value) {
				serial.value = keyword.value;
				let index_product = products.value.findIndex(item => item.serial === serial.value);
				if (index_product >= 0) {
					addToCart(products.value[index_product]);
					keyword.value = '';
					resetSearchForm();
				} else {
					getProducts(warehouse_location_id);
				}
			} else {
				// placeholder_search.value = "Scan Serial Number";
				// keyword_result.value = keyword.value;
				getProducts(warehouse_location_id);
			}
		}
		function addToCart(product) {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			if (product.serial_status == 1) {
				let index_cart = carts.value.findIndex(item => item.product_id === product.product_id);
				if (index_cart >= 0) {
					Swal.fire({
						icon: 'error',
						title: product.serial,
						text: 'Serial number already exist!',
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'OK',
					});
				} else {
					let discount_baht = 0;
					let discount_percent = 0;
					let index_cart = carts.value.findIndex(item => item.model_id === product.model_id);
					if (index_cart >= 0) {
						product.price = carts.value[index_cart].price;
						discount_baht = carts.value[index_cart].discount_baht;
						discount_percent = carts.value[index_cart].discount_percent;
					}
					carts.value.push({
						model_shelf: product.model_shelf,
						product_id: product.product_id,
						model_id: product.model_id,
						serial_status: product.serial_status,
						serial: product.serial,
						warehouse_shelf_id: product.warehouse_shelf_id,
						shelved_address: product.shelved_address,
						name: product.name,
						barcode: product.barcode,
						price: product.price,
						discount_baht: discount_baht,
						discount_percent: discount_percent,
						quantity: 1,
						stock_quantity: product.stock_quantity,
						photo: product.photo,
					});
					resetSearchForm();
				}
			}
			if (product.serial_status == null) {
				let index_cart = carts.value.findIndex(item => item.model_shelf === product.model_shelf);
				if (index_cart >= 0) {
					if (carts.value[index_cart].quantity + 1 > product.stock_quantity) {
						Swal.fire({
							icon: 'error',
							title: 'stock',
							text: `${product.barcode} have ${product.stock_quantity} Psc. in shelf ${product.shelved_address}`,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'OK',
						});
					} else {
						carts.value[index_cart].quantity += 1;
					}
				} else {
					if (product.stock_quantity == 0) {
						Swal.fire({
							icon: 'error',
							title: 'stock',
							text: `${product.barcode} have ${product.stock_quantity} Psc. in shelf ${product.shelved_address}`,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'OK',
						});
					} else {
						carts.value.push({
							model_shelf: product.model_shelf,
							product_id: product.product_id,
							model_id: product.model_id,
							serial_status: product.serial_status,
							serial: product.serial,
							warehouse_shelf_id: product.warehouse_shelf_id,
							shelved_address: product.shelved_address,
							name: product.name,
							barcode: product.barcode,
							price: product.price,
							discount_baht: 0,
							discount_percent: 0,
							quantity: 1,
							stock_quantity: product.stock_quantity,
							photo: product.photo,
						});
					}
				}
			}
			input_search_focus();
		}
		const sumQuantity = computed(() => {
			let sumQuantity = 0;
			carts.value.forEach(item => (sumQuantity += item.quantity));
			return sumQuantity;
		});
		const sumPrice = computed(() => {
			let sumPrice = 0;
			carts.value.forEach(item => (sumPrice += item.quantity * item.price));
			return sumPrice;
		});
		const sumDiscount = computed(() => {
			let sumDiscount = 0;
			carts.value.forEach(item => (sumDiscount += parseFloat(item.discount_baht)));
			return sumDiscount;
		});
		function discountBathToPercentCart() {
			cart_edit_price.value.discount_percent =
				cart_edit_price.value.price == 0 || cart_edit_price.value.price == ''
					? 0
					: (
							(cart_edit_price.value.discount_baht * 100) /
							(cart_edit_price.value.price * cart_edit_price.value.quantity)
					  ).toFixed(2);
		}
		function discountPercentToBathCart() {
			cart_edit_price.value.discount_baht =
				(cart_edit_price.value.discount_percent *
					(cart_edit_price.value.price * cart_edit_price.value.quantity)) /
				100;
		}
		function addDiscount(cart_param) {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			cart_edit_price.value = Object.assign({}, cart_param);
			modal_discount.value = true;
		}
		function saveDiscount() {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			if (cart_edit_price.value.price === '') {
				alert('Please input price');
				return false;
			}
			if (cart_edit_price.value.serial_status == 1) {
				let index_cart = carts.value.findIndex(item => item.product_id === cart_edit_price.value.product_id);
				carts.value[index_cart].price = cart_edit_price.value.price;
				carts.value[index_cart].discount_baht = cart_edit_price.value.discount_baht;
				carts.value[index_cart].discount_percent = cart_edit_price.value.discount_percent;
			}
			if (cart_edit_price.value.serial_status == null) {
				let index_cart = carts.value.findIndex(item => item.model_shelf === cart_edit_price.value.model_shelf);
				carts.value[index_cart].price = cart_edit_price.value.price;
				carts.value[index_cart].discount_baht = cart_edit_price.value.discount_baht;
				carts.value[index_cart].discount_percent = cart_edit_price.value.discount_percent;
			}
			let results = carts.value.filter(item => item.model_id === cart_edit_price.value.model_id);
			results.forEach(function(item) {
				item.price = cart_edit_price.value.price;
				if (item.serial_status == 1) {
					item.discount_baht = cart_edit_price.value.discount_baht;
					item.discount_percent = cart_edit_price.value.discount_percent;
				}
			});
			modal_discount.value = false;
		}

		const netPrice = computed(() => sumPrice.value - sumDiscount.value);

		function addQty(cart, qty) {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			input_search_focus();
			if (cart.serial_status == 1) {
				Swal.fire({
					icon: 'error',
					title: 'stock',
					text: 'Product have serial cannot add more quantity',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK',
				});
			} else if (cart.serial_status == null) {
				if (cart.quantity + 1 > cart.stock_quantity) {
					Swal.fire({
						icon: 'error',
						title: 'stock',
						text: `${cart.barcode} have ${cart.stock_quantity} Psc. in shelf ${cart.shelved_address}`,
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'OK',
					});
				} else {
					cart.quantity += qty;
				}
			}
		}
		function subQty(cart, qty) {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			input_search_focus();
			cart.quantity -= qty;
			if (cart.quantity <= 0) {
				let index = carts.value.indexOf(cart);
				carts.value.splice(index, 1);
			}
		}

		function clearCart() {
			if (order_no.value && is_order_management.value == 1) {
				alert_is_order_management();
				return false;
			}
			input_search_focus();
			Swal.fire({
				title: 'Do you want to delete?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
			}).then(result => {
				if (result.isConfirmed) {
					carts.value.splice(0);
				}
			});
		}

		function openModalCustomer() {
			modal_receipt.value = false;
			modal_form_tax.value = true;
		}
		function closeModalCustomer() {
			modal_receipt.value = true;
		}
		function fillRemain() {
			this.payment.amount = this.netPrice - this.balanceSum;
		}
		function delPayment(pay) {
			if (confirm('Do you want to delete this payment ?')) {
				const new_payments = this.array_payment.filter(function(item) {
					return item != pay;
				});
				this.array_payment = new_payments;
			}
		}

		function clearDataPayment() {
			payment.value = {
				id: null,
				payment_type: '',
				payment_option: '',
				payment_note: '',
				online_order_no: '',
				amount: '',
				attach_file: {
					file_name: '',
					mime_type: '',
					url: '',
				},
				attach_file_url: '',
			};
		}
		const balanceSum = computed(() => {
			let balanceSum = 0;
			array_payment.value.forEach(item => (balanceSum += formatNumberTwoDecimal(item.amount)));

			return balanceSum;
		});
		function addPayment() {
			if (payment.value.amount == '') {
				alert(`Please enter the amount.`);
				return false;
			}
			if (
				paymentTypeLists.value.find(item => item.message == payment.value.payment_type).is_order_no &&
				payment.value.online_order_no == ''
			) {
				alert(`Please enter online order no.`);
				return false;
			}
			array_payment.value.push(payment.value);

			clearDataPayment();

			if (balanceSum.value !== netPrice.value) {
				Swal.fire({
					icon: 'warning',
					title: 'Remaining amount',
					text: ` ${formatNumber(Number(netPrice.value) - Number(balanceSum.value))} Baht`,
				});
			}
			if (balanceSum.value === netPrice.value) {
				Swal.fire({
					icon: 'success',
					title: 'Correct amount ',
					text: ` Total : ${formatNumber(netPrice.value)}`,
				});
			}
		}

		function onFileChange(e) {
			const files = e.target.files || e.dataTransfer.files;
			if (!files.length) {
				return;
			}
			this.createImage(files[0]);
		}
		function createImage(file) {
			const reader = new FileReader();

			reader.onload = e => {
				const attachFile = {
					file_name: file.name,
					mime_type: file.type,
					url: e.target.result,
				};
				this.payment.attach_file = attachFile;
				this.payment.attach_file_url = e.target.result;
			};
			reader.readAsDataURL(file);
		}

		function selectPaymentOption(e) {
			this.payment.payment_option = e.target.value;
		}
		function select_payment_type(event) {
			this.payment.payment_type = event.target.value;
			if (paymentTypeLists.value.find(item => item.message == payment.value.payment_type).option.length > 0) {
				payment.value.payment_option = '';
			} else {
				payment.value.payment_option = payment.value.payment_type;
			}
		}
		function save(status_id, payment = null) {
			pos_order_status_id.value = status_id;
			let text_alert = status_id == 1 ? 'save pending' : 'checkout';

			if (payment == null && status_id == 2) {
				modal_payment_type.value = true;
				return false;
			}

			if (payment == 'savePayment' && status_id == 2 && array_payment.value.length == 0) {
				alert(`Please select at least one`);
				return false;
			}
			if (payment == 'savePayment' && status_id == 2 && this.balanceSum != this.netPrice) {
				alert(`Can't check out because payment is incomplete.`);
				return false;
			}
			if (
				payment == 'savePayment' &&
				status_id == 2 &&
				array_payment.value
					.filter(el => el.payment_type === 'Online')
					.forEach(el => {
						if (el.online_order_no === '') {
							return false;
						}
					})
			) {
				alert(`Please input order no`);
				return false;
			}
			Swal.fire({
				title: `Do you want to ${text_alert} order ${order_no.value} ?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Confirm',
			}).then(result => {
				if (result.isConfirmed) {
					const payments = array_payment.value.map(item => {
						item.attach_file.url == '' && delete item.attach_file;
						return { ...item };
					});
					modal_payment_type.value = false;
					resetSearchForm();
					if (!order_no.value) {
						api.post('api/pos_orders', {
							pos_order_status_id: status_id,
							total_price: sumPrice.value,
							total_pay: balanceSum.value,
							cart: carts.value,
							discount_bath: sumDiscount.value,
							discount_percent: discount_percent.value,
							pos_machine_id: pos_machine_id,
							warehouse_location_id: warehouse_location_id,
							payments: payments,
							bill_type: selectedReceiptOption.value,
						})
							.then(response => {
								getProducts(warehouse_location_id);
								let success_response = response.data;
								useSweetalertTopEnd(1500, 'success', success_response.message);
								formatArrayPayment(success_response.data.pos_order_payments);
								if (status_id == 1) {
									carts.value.splice(0);
									router.replace('/');
								}
								if (status_id == 2) {
									order_no.value = success_response.data.order_no;
									modal_receipt.value = true;
								}
							})
							.catch(error => {
								let error_response = error.response.data;
								useSweetalertError(error_response.message);
							});
					}
					if (order_no.value) {
						api.put(`api/pos_orders/${order_no.value}`, {
							pos_order_status_id: status_id,
							total_price: sumPrice.value,
							total_pay: balanceSum.value,
							cart: carts.value,
							discount_bath: sumDiscount.value,
							discount_percent: discount_percent.value,
							pos_machine_id: pos_machine_id,
							warehouse_location_id: warehouse_location_id,
							payments: payments,
							bill_type: selectedReceiptOption.value,
						})
							.then(response => {
								getProducts(warehouse_location_id);
								let success_response = response.data;
								useSweetalertTopEnd(1500, 'success', success_response.message);
								formatArrayPayment(success_response.data.pos_order_payments);
								if (status_id == 1) {
									carts.value.splice(0);
									if (route.query.order_no_query) {
										router.replace('/orders');
									}
								}
								if (status_id == 2) {
									order_no.value = success_response.data.order_no;
									modal_receipt.value = true;
								}
							})
							.catch(error => {
								let error_response = error.response.data;
								useSweetalertError(error_response.message);
							});
					}
				}
			});
		}
		function formatArrayPayment(objectParam) {
			array_payment.value = objectParam.map(item => {
				const attach_file_url = item.attach_file != '' || item.attach_file != null ? item.attach_file : '';
				return {
					...item,
					attach_file: {
						file_name: '',
						mime_type: '',
						url: '',
					},
					attach_file_url,
				};
			});
		}
		function printReceipt() {
			if (is_order_management.value == 1) {
				axios
					.post(`${process.env.VUE_APP_HOST_ORDER_MANAGEMENT}orders/print_short_tax_invoice`, {
						erp_pos_order_id: order.value.id,
						obj_pos_order: order.value,
					})
					.then(response => {
						console.log(response);
						if (route.query.order_no_query) {
							router.replace('/orders');
						}
						if (!route.query.order_no_query) {
							router.replace('/');
						}
						window.open(`orders/${order_no.value}`);
						modal_receipt.value = false;
						carts.value.splice(0);
						order_no.value = '';
						is_order_management.value = null;
					})
					.catch(error => {
						let error_response = error.response.data;
						useSweetalertError('Error from order management' + error_response.error_message);
					});
			} else {
				if (route.query.order_no_query) {
					router.replace('/orders');
				}
				if (!route.query.order_no_query) {
					router.replace('/');
				}
				window.open(`orders/${order_no.value}`);
				modal_receipt.value = false;
				carts.value.splice(0);
				order_no.value = '';
				is_order_management.value = null;
			}
			array_payment.value.splice(0);
		}
		function resetSearchForm() {
			keyword.value = '';
			barcode.value = '';
			serial.value = '';
			getProducts(warehouse_location_id);
			input_search_focus();
		}
		function printTaxInvoice(tax_invoice) {
			let routeData = router.resolve({
				name: 'InvoicePdf',
				params: { tax_invoice_no: tax_invoice.data.tax_invoice_no },
			});
			window.open(routeData.href, '_blank');
			if (route.query.order_no_query) {
				router.replace('/orders');
			}

			modal_receipt.value = false;
			carts.value.splice(0);
			order_no.value = '';
			customer_id.value = '';
		}
		function input_search_focus() {
			input_search.value.focus();
		}
		function stop_click_right(e) {
			e.preventDefault();
		}
		function handleReceiptTypeChange(value) {
			if (!order_no.value) {
				selectedReceiptOption.value = value;
			} else if(selectedReceiptOption.value !== value) {
				useSweetalertTopEnd(1500, 'warning', "You can't change bill type at edit page.");
			}
		}

		return {
			keyword,
			keyword_result,
			products,
			addToCart,
			carts,
			sumQuantity,
			sumPrice,
			sumDiscount,
			netPrice,
			discount_percent,
			discount_bath,
			addDiscount,
			saveDiscount,
			addQty,
			subQty,
			clearCart,
			openModalCustomer,
			closeModalCustomer,
			save,
			modal_shelf,
			shelf_options,
			modal_receipt,
			modal_discount,
			modal_payment_type,
			order_no,
			printReceipt,
			printTaxInvoice,
			resetSearchForm,
			submitSearchForm,
			saveBarcode,
			formatNumber,
			customer_id,
			getOrderByOrderNo,
			modal_form_tax,
			pos_machine_id,
			barcode,
			shelf_id,
			cart_edit_price,
			discountBathToPercentCart,
			discountPercentToBathCart,
			input_search,
			array_payment,
			payment,
			selectPaymentOption,
			addPayment,
			balanceSum,
			delPayment,
			onFileChange,
			createImage,
			fillRemain,
			clearDataPayment,
			paymentTypeLists,
			select_payment_type,
			stop_click_right,
			selectedReceiptOption,
			handleReceiptTypeChange,
		};
	},
};
</script>
