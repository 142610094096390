<template>
    <div class="print-area p-2">
        <div id="watermark" class="w-full" v-if="receipt.deleted_at!=null">
            <img src="../../assets/image/void.png" alt="void" class="w-10/12">
        </div>
        <div v-for="(receipt_lineitem_pdf, index) in receipt.receipt_lineitem_pdfs" :key="index" class="detail text-xs">
            <div v-if="index!=0" class="page-break"></div>
            <table class="table-stm w-full">
                <tr>
                    <td class="w-1/2">
                        <img :src="receipt.company.path_logo" alt="" class='w-20' ><br>
                        <p class="text-xl">{{ receipt.company.name_th }}</p>
                        <p class="text-md">{{ receipt.company.name_en }}</p>
                        <p class="text-xs">{{ receipt.pos_order_invoice.pos_order_head.address }}</p>
                    </td>
                    <td class="w-1/2 text-center align-top">
                        <p class="order-th text-lg font-bold">
                            <span>ใบเสร็จรับเงิน</span>
                        </p>
                        <p class="order-eng text-lg ">
                            <span>Receipt</span>
                        </p>
                        <!-- <p class="text-center text-xs">ต้นฉบับ (Original)</p> -->
                    </td>

                </tr>
                <tr >
                    <td class="pt-10">
                        <p class="text-xs">โทร. {{ receipt.company.phone }} Fax. {{ receipt.company.fax }}</p>
                        <p class="text-xs">เลขประจำตัวผู้เสียภาษี : {{ receipt.company.tax_no }} สาขา สำนักงานใหญ่</p>
                    </td>
                </tr>
            </table>
            <div class="flex text-xs mt-2">
                <div class="w-3/5 border border-black mr-1 p-1">
                    <table class="table-dealer" width="99%">
                        <tr>
                            <td width="60px"><b>ชื่อลูกค้า</b></td>
                            <td colspan="2">{{ receipt.dealer_full_name }}</td>
                        </tr>
                        <tr>
                            <td style="vertical-align: top;"><b>ที่อยู่</b></td>
                            <td colspan="2" style="vertical-align: top;" rowspan="3">{{ receipt.dealer_address }}</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="2" >
                                <b>เลขประจำตัวผู้เสียภาษี</b>
                            </td>
                            <td width="260px">{{ receipt.dealer_tax_no }} / {{ receipt.dealer_branch_name }}</td>
                        </tr>
                    </table>
                    
                </div>
                <div class="w-2/5 border border-black p-1">
                    <table class="table-dealer" width="100%">
                        <tr>
                            <td align="right"><b>เลขที่ (NO.)</b></td>
                            <td>{{ receipt.document_no }}</td>
                        </tr>
                        <tr>
                            <td align="right"><b>หน้าที่ (PAGE)</b></td>
                            <td>{{ index+1 }}/{{ receipt.receipt_lineitem_pdfs.length }}</td>
                        </tr>
                        <tr>
                            <td align="right"><b>วันท่ี (DATE)</b></td>
                            <td>{{ moment(receipt.document_date).format('D/M/yyy') }}</td>
                        </tr>
                        <tr>
                            <td align="right"><b>วันครบกำหนด (DUE DATE)</b></td>
                            <td>
                                <span v-if="receipt.due_date">{{ moment(receipt.due_date).format('D/M/yyy') }}</span> 
                            </td>
                        </tr>
                        <tr>
                            <td align="right"><b>อ้างอิง</b></td>
                            <td>{{ receipt.pos_order_invoice.tax_invoice_no }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <table class="border border-black mt-2" width="100%">
                <thead>
                    <tr class="border border-black">
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='70px'>PRODUCT CODE<br>รหัสสินค้า</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='200px'>DESCRIPTION<br>รายการสินค้า</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='120px'>Serial Number<br>หมายเลขเครื่อง</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='30px'>QUANTITY<br>จำนวน</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='50px'>UNIT PRICE<br>ราคาหน่วยละ</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='80px'>AMOUNT<br>จำนวนเงิน</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(receipt_lineitem, index_tax) in receipt_lineitem_pdf" :key="index_tax">
                        <td class="border-r border-black" style="vertical-align: top;">{{ receipt_lineitem.barcode }}</td>
                        <td class="border-r border-black align-top">{{ receipt_lineitem.name }}</td>
                        <td class="border-r border-black" align="right">{{ receipt_lineitem.serial }}</td>
                        <td class="border-r border-black align-top" align="center">{{ receipt_lineitem.quantity }}</td>
                        <td class="border-r border-black align-top" align="right">{{ receipt_lineitem.ppe ? formatNumber(receipt_lineitem.ppe) : ''  }}</td>
                        <td class="border-r border-black align-top" align="right">{{ receipt_lineitem.total_not_discount ? formatNumber(receipt_lineitem.total_not_discount) : '' }}</td>
                    </tr>
                    <tr v-for="index_temp in receipt_lineitem_pdf.length < 25 ? 25-receipt_lineitem_pdf.length : 0 " :key="index_temp">
                        <td class="border-r border-black">&nbsp;</td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                    </tr>
                </tbody>
                <tfoot class="border border-black">
                    <tr>
                        <td class="border border-black" colspan="3" align="center">
                            <b>กําหนดแก้ไขเอกสารภายใน 7 วันหากพ้นกําหนดสงวนสิทธิ์ที่จะไม่แก้ไข </b>
                        </td>
                        <td class="border border-black" colspan="2" align="center"><b>รวมเป็นเงิน</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(receipt.total_price) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="3" rowspan="4" style="background-color:rgba(177, 177, 177, 0.288);" align="center">
                            <p class="total_alphabet_thai font-bold">({{ arabicNumberToText(receipt.grand_total | 0) }})</p>
                            <p class="total_alphabet_thai font-bold">(ตัวอักษร)</p>
                        </td>
                        <td class="border border-black" colspan="2" align="center"><b>ส่วนลดการค้า</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(receipt.discount_baht) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="2" align="center"><b>ราคาไม่รวมภาษีมูลค่าเพิ่ม</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(receipt.price_before_vat) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="2" align="center"><b>ภาษีมูลค่าเพิ่ม</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(receipt.vat_price) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="2" align="center"><b>จำนวนเงินรวมทั้งสิ้น</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(receipt.grand_total) }}</td>
                    </tr>
                </tfoot>
            </table>
            <table width=" 100%">
                <tr>
                    <td style="text-align:left">
                        <strong>หมายเหตุ</strong>  {{ receipt.remark }}
                    </td>

                </tr>

            </table>

        </div>
        <div class="footer_print">
            <table class="text-sm my-5" width="100%">
                <tr>
                    <td class="" width="10%">
                        &nbsp;
                    </td>
                    <td class="px-2" width="55%">
                        <p>ผู้รับเงิน</p>
                        <p>collector : ______________</p>
                    </td>
                    <td class="px-2">
                        <p>ผู้มีอำนาจลงนาม</p>
                        <p>Authorized Signature : ______________</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="p-4 w-full flex justify-center">
        <button onclick="window.print();" class="text-white bg-green-400 hover:bg-green-600 text-lg py-2 rounded-2xl w-24 focus:outline-none mx-2"><em class="fas fa-print mr-2"></em>Print</button>
    </div>
          
</template>


<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useFormatNumber, useFormatDate } from '@/js/format'
import { useThaiBathText } from '@/js/thaibath'
import moment from 'moment'
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'

export default {
    name:"TaxInvoice",
    setup(){
        const route = useRoute();
        const receipt = ref("");
        const receipt_no = ref(route.params.receipt_no);
        const formatDate = useFormatDate;
        const formatNumber = useFormatNumber;
        const { arabicNumberToText } = useThaiBathText();

        const getReceiptByReceiptNo = () => { 
            api.get(`api/receipts/${receipt_no.value}`).then((response)=>{
                let success_response = response.data;
                receipt.value = success_response.data
            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertTopEnd(1500, 'error', error_response.message)
            });
        }

        onMounted(() => {
            getReceiptByReceiptNo();
        });


        return { receipt, formatDate, formatNumber, moment, arabicNumberToText }
    }
  
}
</script>
<style scoped>
@page{
    size: A4;
}
@media print {
    div.header_print {
        position: fixed;
        bottom: 0;
    }
    div.footer_print {
        position: fixed;
        bottom: 0;
    }
    .page-break{
        page-break-after: always;
    }
}
#watermark {
    position: absolute;
    opacity: 1;
    /** 
        Set a position in the page for your image
        This should center it vertically
    **/
    top:   8cm;
    left: 2cm;

    /** Your watermark should be behind every content**/
    z-index:  -1000;
    transform: rotate(-35deg);
}
</style>