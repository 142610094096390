<template>
	<div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
		<div
			@click="closeModal"
			class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"
		></div>

		<div class="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 max-h-screen overflow-y-auto">
			<!-- Add margin if you want to see some of the overlay behind the modal-->
			<div class="modal-content py-4 text-left px-6">
				<!--Title-->
				<div class="flex justify-between items-center pb-3">
					<p v-if="form.mode === 'create'" class="font-bold">Create new customer</p>
					<p v-if="form.mode === 'update'" class="font-bold">Update customer</p>
					<div @click="closeModal" class="modal-close cursor-pointer z-50">
						<i class="fas fa-times"></i>
					</div>
				</div>
				<hr class="mb-2" />
				<!--Body-->
				<div>
					<form @submit.prevent="onSubmit" class="w-full pt-5">
						<div class="flex flex-wrap mb-3">
							<div class="w-full px-3">
								<label
									class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									for="full_name"
								>
									Customer Name <span class="text-red-500">*</span>
								</label>
								<input
									v-model="full_name"
									class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
									id="full_name"
									type="text"
									placeholder="Full Name"
									required
								/>
								<p class="text-red-500 text-xs italic">{{ error_msg.full_name }}</p>
							</div>
						</div>
						<div class="flex flex-wrap mb-3">
							<div class="w-full px-3">
								<label
									class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									for="tax_no"
								>
									Tax
								</label>
								<input
									v-model="tax_no"
									class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
									id="tax_no"
									type="text"
									placeholder="Tax No"
								/>
								<p class="text-red-500 text-xs italic">{{ error_msg.tax_no }}</p>
							</div>
						</div>
						<div class="flex flex-wrap mb-3">
							<div class="w-full px-3">
								<label
									class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									for="phone"
								>
									Phone <span class="text-red-500">*</span>
								</label>
								<input
									v-model="phone"
									class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
									id="phone"
									type="text"
									placeholder="Phone"
									required
								/>
								<p class="text-red-500 text-xs italic">{{ error_msg.phone }}</p>
							</div>
						</div>
						<div class="flex flex-wrap mb-3">
							<div class="w-full px-3">
								<label
									class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									for="email"
								>
									Email
								</label>
								<input
									v-model="email"
									class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
									id="email"
									type="email"
									placeholder="Email"
								/>
								<p class="text-red-500 text-xs italic">{{ error_msg.email }}</p>
							</div>
						</div>

						<div class="grid grid-cols-12 px-3 gap-2 mb-4">
							<div class="col-span-12 md:col-span-12">
								<label
									class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									for="address"
								>
									Address
								</label>
								<input
									type="text"
									v-model="address"
									id="address"
									required
									placeholder="Address"
									class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2.5 px-4  leading-tight focus:outline-none focus:border-green-400"
								/>
								<p class="text-red-500 text-xs italic">{{ error_msg.address }}</p>
							</div>
						</div>


						<!--Footer-->
						<div class="flex justify-end pt-2">
							<button
								@click="clearForm"
								type="button"
								class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2"
							>
								Clear
							</button>
							<button
								type="submit"
								class="p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-2"
							>
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from 'vue';
import api from '@/services/auth';
import { useSweetalertTopEnd } from '@/js/sweetalert';
import store from '@/store/index.js';

export default {
	name: 'ModalForm',
	emits: ['saveCustomer', 'customerLevel'],
	components: {},

	setup(props, { emit }) {
		const error_msg = reactive({
			full_name: '',
			tax_no: '',
			customer_level_id: '',
			phone: '',
			email: '',
		});
        const form = computed(() => store.state.customers.form);
        const full_name = computed({
            get() {
                return store.state.customers.form.full_name
            },
            set(value) {
                store.commit('customers/setFormName', value)
            }
        })
        const phone = computed({
            get() {
                return store.state.customers.form.phone
            },
            set(value) {
                store.commit('customers/setFormPhone', value)
            }
        })
        const email = computed({
            get() {
                return store.state.customers.form.email
            },
            set(value) {
                store.commit('customers/setFormEmail', value)
            }
        })
        const tax_no = computed({
            get() {
                return store.state.customers.form.tax_no
            },
            set(value) {
                store.commit('customers/setFormTaxNo', value)
            }
        })
        const address = computed({
            get() {
                return store.state.customers.form.address
            },
            set(value) {
                store.commit('customers/setFormAddress', value)
            }
        })

		const onSubmit = () => {
                api({
                    method: form.value.mode === 'create' ? 'POST' : 'PUT',
                    url: form.value.mode === "create" ? 'api/customers' : `api/customers/${form.value.id}`,
                    data: {
                        full_name: form.value.full_name,
                        tax_no: form.value.tax_no,
                        phone: form.value.phone,
                        email: form.value.email,
                        address: form.value.address,
                    }
                })
                .then((response) => {
                    emit('saveCustomer', form.value.mode);
                    closeModal();
                    clearForm();
                    const success_response = response.data;
                    useSweetalertTopEnd(1500, 'success', success_response.message);

                })
                .catch((error) => {
                    clearError();
                    const error_response = error.response.data;
                    useSweetalertTopEnd(1500, 'error', error_response.message);
                    if (error_response.status == 422) {
                        for (const key in error_response.errors) {
                            error_msg[key] = error_response.errors[key][0];
                        }
                    }
                    });
		};
		const closeModal = () => {
            store.commit('customers/setModalFormCustomersFalse')
		};
		const clearForm = () => {
            store.commit('customers/setClearForm')
			clearError();
		};
		const clearError = () => {
			error_msg.full_name = '';
			error_msg.tax_no = '';
			error_msg.customer_level_id = '';
			error_msg.phone = '';
			error_msg.email = '';
			error_msg.address = '';
			error_msg.expired_at = '';
		};

		return {
			form,
            full_name,
            tax_no,
            phone,
            email,
            address,
			error_msg,
			onSubmit,
			clearForm,
			clearError,
			closeModal,
		};
	},
};
</script>
