<template>
    <div class="relative">
        <div id="watermark_void" class="" v-if="order.pos_order_invoice">
            <img src="../../assets/image/void.png" alt="void" class="w-full" id="image_void" />
        </div>
        <div id="watermark_cancel" class="" v-if="order.deleted_at != null">
            <img src="../../assets/image/cancel.png" alt="cancel" class="w-full" id="image_cancel" />
        </div>
        <div class="text-center" :class="index != 1 && 'mt-3'">
            <img
                v-if="order?.bill_type === 'Tax'"
                src="../../assets/image/stm-no-title.png"
                alt="STM POS"
                class="mb-3 w-20 inline-block"
                id="photo_logo"
            />
            <img
                v-if="order?.bill_type === 'Non-tax'"
                src="../../assets/image/smartcam.png"
                alt="STM POS"
                class="mb-3 w-20 inline-block"
                id="photo_logo"
            />
            <p v-if="order?.bill_type === 'Tax'" class="font-bold text-sm">{{ company.name_th }}</p>
            <p v-if="order?.bill_type === 'Non-tax'" class="font-bold text-sm">SMARTCAM 1988</p>
            <p v-if="order?.bill_type === 'Tax'" class="text-sm">POS ID {{ order.pos_machine.name }}</p>
            <p v-if="order?.bill_type === 'Tax'" class="text-sm">สาขา {{ order.warehouse_location.branch_id }}</p>
            <p v-if="order?.bill_type === 'Tax'" class="text-sm">เลขประจำตัวผู้เสียภาษี : {{ order.warehouse_location.tax_no }}</p>
            <p v-if="order?.bill_type === 'Tax'" class="text-sm">{{ order.address }}</p>
            <p v-if="order?.bill_type === 'Non-tax'" class="text-sm">3133/4 ซอยสุขุมวิท 101/2 แขวงบางนาเหนือ เขตบางนา กรุงเทพมหานคร 10260</p>
            <p class="text-sm">โทร {{ company.phone }}</p>
        </div>
        <div class="mt-4 text-center">
            <p class="font-bold text-sm">{{ order?.bill_type ? order?.bill_type === 'Tax' ? 'ใบกำกับภาษีอย่างย่อ' : 'ใบรับเงินชั่วคราว' : 'ใบกำกับภาษีอย่างย่อ/ใบรับเงินชั่วคราว' }}</p>
        </div>
        <hr class="my-1" />
        <p class="text-center text-sm">{{ order.order_no }}</p>
        <p class="text-center text-sm">Date : {{ moment(order.created_at).format('D/M/yyy h:mm a') }}</p>
        <div>
            <table class="w-full text-sm">
                <tbody>
                    <tr>
                        <td width="120px">Cashier</td>
                        <td>:</td>
                        <td>{{ order.user?.employee?.firstname }} {{ order.user?.employee?.lastname }}</td>
                    </tr>
                    <tr>
                        <td>รหัสลูกค้า</td>
                        <td>:</td>
                        <td class="">-</td>
                    </tr>
                    <tr>
                        <td v-if="payments[0]?.payment_type != 'Online'" colspan="3">
                            {{ payments.map(item => item.payment_type).join(', ') }}
                        </td>
                        <td v-if="payments[0]?.payment_type == 'Online'">
                            {{ payments.map(item => item.payment_type).join(', ') }}
                        </td>
                        <td v-if="payments[0]?.payment_type == 'Online'">:</td>
                        <td v-if="payments[0]?.payment_type == 'Online'">
                            {{ online_order_no.map(item => item.online_order_no).join(', ') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr class="my-3" />
        <div>
            <table class="w-full text-sm">
                <thead>
                    <tr>
                        <td class="text-center" colspan="4">รายการสินค้า</td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="pos_order_line_item_tax_receipt in order.pos_order_line_item_tax_receipts"
                        :key="pos_order_line_item_tax_receipt.model_id"
                        class="text-xs"
                    >
                        <td class="text-center align-top">{{ pos_order_line_item_tax_receipt.number }}</td>
                        <td
                            v-if="pos_order_line_item_tax_receipt.name.startsWith('s/n :')"
                            colspan="3"
                            class="text-left"
                        >
                            <span>{{ pos_order_line_item_tax_receipt.name }}</span>
                        </td>
                        <td v-if="!pos_order_line_item_tax_receipt.name.startsWith('s/n :')" class="text-left">
                            <span>{{ pos_order_line_item_tax_receipt.name }}</span>
                        </td>
                        <td
                            v-if="!pos_order_line_item_tax_receipt.name.startsWith('s/n :')"
                            class="text-center align-top"
                        >
                            <p>{{ pos_order_line_item_tax_receipt.quantity }}</p>
                        </td>
                        <td
                            v-if="!pos_order_line_item_tax_receipt.name.startsWith('s/n :')"
                            class="text-right align-top"
                        >
                            <p v-if="pos_order_line_item_tax_receipt.total_not_discount !== ''">
                                {{ formatNumber(pos_order_line_item_tax_receipt.total_not_discount) }}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr class="my-2" />
        <div class="flex text-sm">
            <div class="flex-grow">รวมจำนวนสินค้า</div>
            <div class="text-right">{{ formatNumber(total_product) }}</div>
        </div>
        <div class="flex text-sm">
            <div class="flex-grow">รวมราคาสินค้า</div>
            <div class="text-right">{{ formatNumber(order.total_price) }}</div>
        </div>
        <div class="flex text-sm ">
            <div class="flex-grow">ส่วนลดการค้า</div>
            <div class="text-right">{{ formatNumber(order.discount_bath) }}</div>
        </div>
        <div class="flex text-sm">
            <div class="flex-grow">รวมเป็นเงินทั้งสิ้น</div>
            <div class="text-right">{{ formatNumber(order.grand_total) }}</div>
        </div>
        <div v-if="order?.bill_type === 'Tax'" class="flex text-sm">
            <div class="flex-grow">มูลค่าสินค้า</div>
            <div class="text-right">{{ formatNumber(order.price_before_vat) }}</div>
        </div>
        <div v-if="order?.bill_type === 'Tax'" class="flex text-sm ">
            <div class="flex-grow font-bold">ภาษีมูลค่าเพิ่ม 7%</div>
            <div class="text-right">{{ formatNumber(order.vat_price) }}</div>
        </div>
        <div class="text-center">
            <p v-if="order?.bill_type === 'Tax'">VAT INCLUDED</p>
            <p>ขอบคุณที่ใช้บริการ</p>
        </div>
    </div>
</template>

<script>
import { useFormatNumber, useFormatDate } from '@/js/format';
import moment from 'moment';

export default {
	name: 'ReceiptContent',
    props: ['order', 'total_product', 'company', 'payments', 'online_order_no', 'index'],
	setup() {
		const formatDate = useFormatDate;
		const formatNumber = useFormatNumber;

		return { formatDate, formatNumber, moment };
	},
};
</script>
<style scope>
@page {
	margin: 0;
}
@font-face {
	font-family: myFirstFont;
	src: url(../../assets/font/f25_bank_printer/F25_Bank_Printer_Bold.ttf);
}
@font-face {
	font-family: myFirstFont2;
	src: url(../../assets/font/kravitz/test.TTF);
}
@font-face {
	font-family: TahomaFont;
	src: url(../../assets/font/Tahoma-Font/TAHOMA_0.TTF);
}
#receipt-content-page {
	font-family: myFirstFont;
	width: 80mm;
}
#image_void {
	width: 430px;
}
#watermark_void {
	position: absolute;
	opacity: 1;
	/** 
        Set a position in the page for your image
        This should center it vertically
    **/
	top: 4cm;
	left: 13.5cm;

	/** Your watermark should be behind every content**/
	z-index: -1000;
	transform: rotate(-35deg);
}
#image_cancel {
	width: 500px;
}
#watermark_cancel {
	position: absolute;
	opacity: 1;
	/** 
        Set a position in the page for your image
        This should center it vertically
    **/
	top: 1cm;
	left: 12.5cm;

	/** Your watermark should be behind every content**/
	z-index: -1000;
	transform: rotate(-35deg);
}
@media print {
	#image_void {
		width: 90%;
	}
	#watermark_void {
		position: absolute;
		opacity: 1;
		/** 
            Set a position in the page for your image
            This should center it vertically
        **/
		top: 4cm;
		left: 1cm;

		/** Your watermark should be behind every content**/
		z-index: -1000;
		transform: rotate(-35deg);
	}
	#image_cancel {
		width: 100%;
	}
	#watermark_cancel {
		position: absolute;
		opacity: 1;
		/** 
            Set a position in the page for your image
            This should center it vertically
        **/
		top: 0cm;
		left: 1cm;

		/** Your watermark should be behind every content**/
		z-index: -1000;
		transform: rotate(-35deg);
	}
	.page-break {
		page-break-after: always;
	}
}
</style>
