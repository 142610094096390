<template>
    <div class="flex flex-col w-full px-5 mx-2 bg-white rounded-2xl pb-2">
      <div class="flex items-center justify-between mt-5 mb-2">
          <h2 class="my-6 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
              Invoices ({{ total }})
          </h2>
          <div class="flex justify-center flex-1 lg:mr-32">
              <div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
                  <div class="absolute inset-y-0 flex items-center pl-2">
                      <i class="fas fa-search"></i>
                  </div>
                  <form @submit.prevent="submitSearchForm">
                      <input
                          v-model="keyword"
                          class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md"
                          type="text"
                          placeholder="Search"
                          aria-label="Search"
                      />
                  </form>
              </div>
              <div>
                  <button @click="submitSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                      <i class="fas fa-search mr-2"></i>
                      <span>Search</span>
                  </button>
              </div>
              <div>
                  <button @click="resetSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-red-500 transition-colors duration-150 border-red-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-purple">
                      <i class="fas fa-sync mr-2"></i>
                      <span>Clear</span>
                  </button>
              </div>
          </div>
      </div>

      <div class="w-full overflow-hidden rounded-lg shadow-sm">
          <div class="h-full overflow-y-auto">
              <table class="w-full whitespace-no-wrap">
                  <thead>
                      <tr class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                          <th class="px-4 py-3">#</th>
                          <th class="px-4 py-3">Invoice Date</th>
                          <th class="px-4 py-3">Invoice No.</th>
                          <th class="px-4 py-3">Receipt</th>
                          <th class="py-3">Customer</th>
                          <th class="px-4 py-3">Customer site</th>
                          <th class="pr-2 py-3 text-right">Total Price</th>
                          <th class="px-4 py-3">{{loading}}</th>
                      </tr>
                  </thead>
                  <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800" v-if="loading">
      
                      <tr v-for="(invoice, index) in invoices" :key="invoice.id" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100" :class="invoice.deleted_at!=null ? 'line-through text-red-500': ''">
                          <td class="px-4 py-3 text-sm">{{ (current_page-1) * per_page + (index+1) }}</td>
                          <td class="px-4 py-3 text-sm">{{ formatDate(invoice.tax_invoice_date) }}</td>
                          <td class="px-4 py-3 text-sm">
                              <div class="flex items-center text-sm">
                                  <div>
                                    <p class="font-semibold">{{ invoice.tax_invoice_no }}</p>
                                    <p class="text-xs">Ref. {{ invoice.pos_order_head.order_no }}</p>
                                  </div>
                              </div>
                          </td>
                          <td class="py-3 text-sm">
                              <div v-if="invoice.pos_order_head.pos_machine.warehouse_location_id==1">
                                <button v-if="!invoice.receipt && invoice.deleted_at==null" @click="openModalReceipt(invoice.tax_invoice_no)" class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple">
                                    <em class="fas fa-plus"></em> Receipt
                                </button>
                                <p v-if="invoice.receipt">
                                    {{ invoice.receipt.document_no }}
                                    <span v-if="invoice.receipt.pos_status==1">
                                        <button @click="openModalReceipt(invoice.tax_invoice_no)" class="px-2 py-1 mx-1 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple">
                                            <em class="fas fa-edit"></em>
                                        </button>
                                        <button @click="deleteReceipt(invoice.receipt.document_no)" class="px-2 py-1 mx-1 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
                                            <em class="fas fa-times"></em>
                                        </button>
                                    </span>
                                </p>
                              </div>
                          </td>
                          <td class="py-3 text-sm">{{ invoice.pos_customer.full_name }}</td>
                          <td class="pr-2 py-3 text-sm">{{ invoice.customer_site }}</td>
                          <td class="pr-2 py-3 text-sm text-right">{{ formatNumber(invoice.grand_total) }}</td>
                          <td class="px-4 py-3 text-sm text-center">
                            <!-- BTN Tax invoice start -->
                            <button @click="printTaxInvoice(invoice.tax_invoice_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple">
                                  <em class="fas fa-file-invoice-dollar"></em>
                            </button>
                            <!-- BTN Tax invoice end -->
                            <!-- BTN Receipt start -->
                            <button v-if="invoice.receipt" @click="printReceipt(invoice.receipt.document_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple">
                                  <strong>R</strong>
                            </button>
                            <button v-if="!invoice.receipt" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple">
                                  <strong>R</strong>
                            </button>
                            <!-- BTN Receipt end -->
                            
                            <!-- BTN Edit start -->
                            <button v-if="!invoice.is_complete && invoice.deleted_at==null" @click="openModalTax(invoice.pos_order_head.order_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple">
                                <em class="far fa-edit"></em>
                            </button>
                            <button v-if="invoice.is_complete || invoice.deleted_at!=null" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple">
                                  <em class="fas fa-edit"></em>
                            </button>
                            <!-- BTN Edit start -->

                            <!-- BTN Delete start -->
                            <button v-if="!invoice.is_complete && invoice.deleted_at==null" @click="deleteTax(invoice.tax_invoice_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
                                <em class="fas fa-times"></em>
                            </button>
                            <button v-if="invoice.is_complete || invoice.deleted_at!=null" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple">
                                  <em class="fas fa-times"></em>
                            </button>
                            <!-- BTN Delete start -->
                          </td>
                          
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <VueTailwindPagination
            :current="current_page"
            :total="total"
            :per-page="per_page"       
            @page-changed="getPosOrderInvoices($event)"         
        />
    </div>


    <!--Modal Tax-->
    <span v-if="modal_form_tax">
      <TaxModal :modal_form_tax_props="modal_form_tax" :order_no_props="order_no" @closeModalTax="modal_form_tax=false" @saveTax="saveTax"/>
    </span>
    <!--Modal Tax-->
    <!--Modal Receipt-->
    <span v-if="modal_form_receipt">
      <ReceiptModal :modal_form_receipt_props="modal_form_receipt" :tax_invoice_no_props="tax_invoice_no" @closeModalReceipt="modal_form_receipt=false" @saveReceipt="saveReceipt"/>
    </span>
    <!--Modal Receipt-->
    
</template>

<script>
import { ref, onMounted } from "vue";
import { useGetInvoices, printTaxInvoice } from '@/js/pos_order_invoice'
import { useModalReceipt, printReceipt } from '@/js/receipt'
import TaxModal from '@/components/TaxModal.vue'
import ReceiptModal from '@/components/ReceiptModal.vue'
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import "@ocrv/vue-tailwind-pagination/dist/style.css"
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
import { useFormatNumber, useFormatDate } from '@/js/format'
import Swal from 'sweetalert2'

export default {
    name:"Order",
    components:{
        TaxModal,
        ReceiptModal,
        VueTailwindPagination
    },
    setup(){
        const { invoices, loading, total, current_page, per_page, getPosOrderInvoices } = useGetInvoices();
        const { tax_invoice_no, modal_form_receipt, openModalReceipt } = useModalReceipt();
        const keyword = ref("");
        const order_no = ref("");
        const customer_id = ref("");
        const formatNumber = useFormatNumber;
        const formatDate = useFormatDate;
        const modal_form_tax = ref(false)
        const pos_warehouse_location_id = localStorage.getItem('pos_warehouse_location_id')

        onMounted(() => {
            getPosOrderInvoices(1);
        });
        

        function submitSearchForm(){
            current_page.value = 1
            getPosOrderInvoices(current_page.value, keyword.value);
        }
        function resetSearchForm(){
            keyword.value = ""
            current_page.value = 1
            getPosOrderInvoices(current_page.value, keyword.value);
        }

        function openModalTax(order_no_param){
            modal_form_tax.value = true
            order_no.value = order_no_param
        }
        function deleteTax(tax_invoice_no_param){
            Swal.fire({
                title: 'Do you want to void invoice '+tax_invoice_no_param+' ?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, void it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    api.delete(`api/pos_order_invoices/${tax_invoice_no_param}`).then(()=>{
                        getPosOrderInvoices(current_page.value, keyword.value);

                    }).catch( error => {
                        let error_response = error.response.data;
                        useSweetalertTopEnd(1500, 'error', error_response.message)
                    });
                }
            })
        }
        function saveTax(){
            getPosOrderInvoices(current_page.value, keyword.value);
        }

        // Receipt
        function deleteReceipt(receipt_no_param){
            Swal.fire({
                title: 'Do you want to void receipt '+receipt_no_param+' ?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, void it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    api.delete(`api/receipts/${receipt_no_param}`).then(()=>{
                        getPosOrderInvoices(current_page.value, keyword.value);
                    }).catch( error => {
                        let error_response = error.response.data;
                        useSweetalertTopEnd(1500, 'error', error_response.message)
                    });
                }
            })
        }
        function saveReceipt(){
            getPosOrderInvoices(current_page.value, keyword.value);
        }


        return { 
            invoices, 
            loading,
            pos_warehouse_location_id,
            tax_invoice_no, 
            total,
            current_page,
            per_page,
            getPosOrderInvoices,
            formatNumber,
            formatDate,
            customer_id, 
            saveTax, 
            printTaxInvoice,
            submitSearchForm,
            keyword,
            resetSearchForm,
            openModalTax,
            deleteTax,
            modal_form_tax,
            modal_form_receipt,
            order_no,
            openModalReceipt,
            deleteReceipt,
            saveReceipt,
            printReceipt
        }
  }
  
}
</script>