<template>
    <div v-if="loading" class="print-area p-2" @contextmenu="stop_click_right($event)">
        <div id="watermark" class="w-full" v-if="tax_invoice.deleted_at!=null">
            <img src="../../assets/image/void.png" alt="void" class="w-10/12">
        </div>
        <div v-for="(pos_order_invoice_lineitem_tax_invoice, index) in tax_invoice.pos_order_invoice_lineitem_tax_invoices" :key="index" class="detail text-xs">
            <div v-if="index!=0" class="page-break"></div>
            <table class="table-stm w-full">
                <tr>
                    <td class="w-1/2">
                        <img :src="tax_invoice.company.path_logo" alt="" class='w-20' ><br>
                        <p class="text-xl">{{ tax_invoice.company.name_th }}</p>
                        <p class="text-md">{{ tax_invoice.company.name_en }}</p>
                        <p class="text-xs">{{ tax_invoice.pos_order_head.address }}</p>
                    </td>
                    <td class="w-1/2 text-center align-top">
                        <p class="order-th text-lg font-bold">
                            <span v-if="tax_invoice.pos_order_head.pos_machine.warehouse_location_id==1">ใบแจ้งหนี้/ใบกำกับภาษี</span>
                            <span v-if="tax_invoice.pos_order_head.pos_machine.warehouse_location_id!=1">ใบกำกับภาษี/ใบเสร็จ</span>
                        </p>
                        <p class="order-eng text-lg ">
                            <span v-if="tax_invoice.pos_order_head.pos_machine.warehouse_location_id==1">Invoice/Tax Invoice</span>
                            <span v-if="tax_invoice.pos_order_head.pos_machine.warehouse_location_id!=1">Invoice/Receipt</span>
                        </p>
                        <!-- <p class="text-center text-xs">ต้นฉบับ (Original)</p> -->
                    </td>

                </tr>
                <tr >
                    <td class="pt-10">
                        <p class="text-xs">โทร. {{ tax_invoice.company.phone }} Fax. {{ tax_invoice.company.fax }}</p>
                        <p class="text-xs">เลขประจำตัวผู้เสียภาษี : {{ tax_invoice.pos_order_head.warehouse_location.tax_no }} สาขา {{ tax_invoice.pos_order_head.warehouse_location.branch_id }}</p>
                    </td>
                </tr>
            </table>
            <div class="flex text-xs mt-2">
                <div class="w-3/5 border border-black mr-1 p-1">
                    <table class="table-dealer" width="99%">
                        <tr>
                            <td width="60px"><b>ชื่อลูกค้า</b></td>
                            <td colspan="2">{{ tax_invoice.dealer_full_name }}</td>
                        </tr>
                        <tr>
                            <td style="vertical-align: top;"><b>ที่อยู่</b></td>
                            <td colspan="2" style="vertical-align: top;" rowspan="3">{{ tax_invoice.dealer_address }}</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="2" >
                                <b>เลขประจำตัวผู้เสียภาษี</b>
                            </td>
                            <td width="260px">{{ tax_invoice.dealer_tax_no }} / {{ tax_invoice.customer_site }}</td>
                        </tr>
                    </table>
                    
                </div>
                <div class="w-2/5 border border-black p-1">
                    <table class="table-dealer" width="100%">
                        <tr>
                            <td align="right"><b>เลขที่ (NO.)</b></td>
                            <td>{{ tax_invoice.tax_invoice_no }}</td>
                        </tr>
                        <tr>
                            <td align="right"><b>หน้าที่ (PAGE)</b></td>
                            <td>{{ index+1 }}/{{ tax_invoice.pos_order_invoice_lineitem_tax_invoices.length }}</td>
                        </tr>
                        <tr>
                            <td align="right"><b>วันท่ี (DATE)</b></td>
                            <td>{{ moment(tax_invoice.tax_invoice_date).format('D/M/yyy') }}</td>
                        </tr>
                        <tr>
                            <td align="right"><b>วันครบกำหนด (DUE DATE)</b></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td align="right"><b>อ้างอิง</b></td>
                            <td>{{ tax_invoice.pos_order_head.order_no }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <table class="border border-black mt-2" width="100%">
                <thead>
                    <tr class="border border-black">
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='70px'>PRODUCT CODE<br>รหัสสินค้า</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='200px'>DESCRIPTION<br>รายการสินค้า</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='120px'>Serial Number<br>หมายเลขเครื่อง</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='30px'>QUANTITY<br>จำนวน</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='50px'>UNIT PRICE<br>ราคาหน่วยละ</th>
                        <th class="border border-black" style="background-color:rgba(177, 177, 177, 0.288);" width='80px'>AMOUNT<br>จำนวนเงิน</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(tax_invoice, index_tax) in pos_order_invoice_lineitem_tax_invoice" :key="index_tax">
                        <td class="border-r border-black" style="vertical-align: top;">{{ tax_invoice.barcode }}</td>
                        <td class="border-r border-black align-top">{{ tax_invoice.name }}</td>
                        <td class="border-r border-black" align="right">{{ tax_invoice.serial }}</td>
                        <td class="border-r border-black align-top" align="center">{{ tax_invoice.quantity }}</td>
                        <td class="border-r border-black align-top" align="right">{{ tax_invoice.ppe ? formatNumber(tax_invoice.ppe) : ''  }}</td>
                        <td class="border-r border-black align-top" align="right">{{ tax_invoice.total_not_discount ? formatNumber(tax_invoice.total_not_discount) : '' }}</td>
                    </tr>
                    <tr v-for="index_temp in pos_order_invoice_lineitem_tax_invoice.length < 25 ? 25-pos_order_invoice_lineitem_tax_invoice.length : 0 " :key="index_temp">
                        <td class="border-r border-black">&nbsp;</td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                        <td class="border-r border-black"></td>
                    </tr>
                </tbody>
                <tfoot class="border border-black">
                    <tr>
                        <td class="border border-black" colspan="3" align="center">
                            <b>กําหนดแก้ไขเอกสารภายใน 7 วันหากพ้นกําหนดสงวนสิทธิ์ที่จะไม่แก้ไข </b>
                        </td>
                        <td class="border border-black" colspan="2" align="center"><b>รวมเป็นเงิน</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(tax_invoice.total_price) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="3" rowspan="4" style="background-color:rgba(177, 177, 177, 0.288);" align="center">
                            <p class="total_alphabet_thai font-bold">({{ arabicNumberToText(tax_invoice.grand_total | 0) }})</p>
                            <p class="total_alphabet_thai font-bold">(ตัวอักษร)</p>
                        </td>
                        <td class="border border-black" colspan="2" align="center"><b>ส่วนลดการค้า</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(tax_invoice.discount_baht) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="2" align="center"><b>ราคาไม่รวมภาษีมูลค่าเพิ่ม</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(tax_invoice.price_before_vat) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="2" align="center"><b>ภาษีมูลค่าเพิ่ม</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(tax_invoice.vat_price) }}</td>

                    </tr>
                    <tr>
                        <td class="border border-black" colspan="2" align="center"><b>จำนวนเงินรวมทั้งสิ้น</b></td>
                        <td class="border border-black" align="right">{{ formatNumber(tax_invoice.grand_total) }}</td>
                    </tr>
                </tfoot>
            </table>
            <table width=" 100%">
                <tr>
                    <td style="text-align:left">
                        <b>หมายเหตุ</b>  {{ tax_invoice.note }}
                    </td>

                </tr>

            </table>

        </div>
        <div class="footer_print">
            <p class="text-xs">(1) ได้รับสินค้าตามรายการข้างต้นถูกต้องเรียบร้อย</p>
            <p class="text-xs">(2) หากเกิดความเสียหาย หรือขาดตกบกพร่องกับสินค้าในรายการข้างต้น โปรดแจ้งเป็นให้ทางเราทราบ</p>
            <p class="text-xs">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เป็นลายลักษณ์อักษร หลังจากรับสินค้าไว้ หากพ้นกำหนดผู้ขายจะถือว่าผู้ซื้อได้รับสินค้าถูกต้องแล้ว</p>
            <table class="text-sm my-5" width="100%">
                <tr>
                    <td class="" width="10%">
                        &nbsp;
                    </td>
                    <td class="px-2">
                        <p>ผู้รับสินค้า</p>
                        <p>Received by : ______________</p>
                    </td>
                    <td class="px-2">
                        <p>ผู้ส่งสินค้า</p>
                        <p>Delivered by : ______________</p>
                    </td>
                    <td class="px-2">
                        <p>อนุมัติโดย</p>
                        <p>Approved by : ______________</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="p-4 w-full flex justify-center">
        <button @click="print" class="text-white bg-green-400 hover:bg-green-600 text-lg py-2 rounded-2xl w-24 focus:outline-none mx-2"><em class="fas fa-print mr-2"></em>Print</button>
    </div>
          
</template>


<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useGetInvoiceByInvoiceNo } from '@/js/pos_order_invoice'
import { useFormatNumber, useFormatDate } from '@/js/format'
import { useThaiBathText } from '@/js/thaibath'
import moment from 'moment'
import {  useSweetalertError } from '@/js/sweetalert'

export default {
    name:"TaxInvoice",
    setup(){
        const tax_invoice_no = ref("");
        const route = useRoute();
        const { tax_invoice, getInvoiceByInvoiceNo, loading } = useGetInvoiceByInvoiceNo();
        const formatDate = useFormatDate;
        const formatNumber = useFormatNumber;
        const { arabicNumberToText } = useThaiBathText();

        tax_invoice_no.value = route.params.tax_invoice_no;

        onMounted(() => {
            getInvoiceByInvoiceNo(tax_invoice_no.value);
        });
        function stop_click_right(e){
            e.preventDefault()
        }
        function print(){
            console.log(tax_invoice.value.id);
            if(tax_invoice.value.pos_order_head.is_order_management===1){
                axios.post(`${process.env.VUE_APP_HOST_ORDER_MANAGEMENT}orders/print_tax_invoice`,{
                erp_pos_order_id : tax_invoice.value.pos_order_head.id,
                obj_pos_order : tax_invoice.value.pos_order_head,
                obj_pos_order_invoice_id : tax_invoice.value.id,
                obj_pos_order_invoice : tax_invoice.value,
                }).then((response)=>{
                    console.log(response);
                    window.print();
                }).catch( error => {
                    useSweetalertError("Error from order management : " + error.response.data?.error_message);
                });
            }else{
                window.print();
            }
        }

        return { tax_invoice, formatDate, formatNumber, loading, moment, arabicNumberToText, print, stop_click_right }
    }
  
}
</script>
<style scoped>
@page{
    size: A4;
}
@media print {
    div.header_print {
        position: fixed;
        bottom: 0;
    }
    div.footer_print {
        position: fixed;
        bottom: 0;
    }
    .page-break{
        page-break-after: always;
    }
}
#watermark {
    position: absolute;
    opacity: 1;
    /** 
        Set a position in the page for your image
        This should center it vertically
    **/
    top:   8cm;
    left: 2cm;

    /** Your watermark should be behind every content**/
    z-index:  -1000;
    transform: rotate(-35deg);
}
</style>