import axios from "axios";
import store from '@/store/index.js';
import { useRouter } from "vue-router";

const Auth = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    // "Authorization": `Bearer ${token}`
  },
});

Auth.interceptors.request.use(config => {

  let access_token = ''

  try{
    let userStorage = localStorage.getItem('user')
    let userStorageJSON = JSON.parse(userStorage)
    access_token = userStorageJSON['access_token']
  }catch(error){
    console.log(error);
  }

  if(access_token){
    config.headers.Authorization = "Bearer "+access_token
  }

  return config

})

Auth.interceptors.request.use((config) => {
  store.commit('setLoading', true)
  return config;
}, (error) => {
  store.commit('setLoading', false)
  alert(error)
  // trigger 'loading=false' event here
  return Promise.reject(error);
});

Auth.interceptors.response.use((response) => {
  store.commit('setLoading', false)
  return response;
}, (error) => {
  store.commit('setLoading', false)
  const router = useRouter();
  if(error.response.status==401){
    localStorage.removeItem('user')
    router.push("/login");
  }else{
    alert(error)
  }
  // trigger 'loading=false' event here
  return Promise.reject(error);
});

export default Auth;