import { ref } from "vue";
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'

export function useModalReceipt(){
    const tax_invoice_no = ref("")
    const modal_form_receipt = ref(false)

    function openModalReceipt(tax_invoice_no_param){
        modal_form_receipt.value = true
        tax_invoice_no.value = tax_invoice_no_param
    }
    // function deleteReceipt(receipt_no_param, page, current_page, keyword){
    //     Swal.fire({
    //         title: 'Do you want to void receipt '+receipt_no_param+' ?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, void it!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             api.delete(`api/receipts/${receipt_no_param}`).then(()=>{
    //                 if(page=="invoice"){
    //                     getPosOrderInvoices(current_page, keyword);
    //                 }

    //             }).catch( error => {
    //                 let error_response = error.response.data;
    //                 useSweetalertTopEnd(1500, 'error', error_response.message)
    //             });
    //         }
    //     })
    // }
    
    return { tax_invoice_no, modal_form_receipt, openModalReceipt }
}


export function useGetReceiptByReceiptNo(){
    const receipt = ref([]);
    const loading_receipt_no = ref(false);

    const getReceiptByReceiptNo = async (receipt_no) => { 
        await api.get(`api/receipts/${receipt_no}`).then((response)=>{
            let success_response = response.data;
            receipt.value = success_response.data
            loading_receipt_no.value = true
        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    return { receipt, getReceiptByReceiptNo, loading_receipt_no };
}
export function printReceipt(receipt_no){
    // window.open("/receipts/"+receipt_no, '_blank');
    api.get(`api/receipts/${receipt_no}/pdf`, {responseType: "blob"}).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
    })
}