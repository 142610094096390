<template>
	<div class="bg-white rounded-3xl flex flex-col h-full shadow">
		<div class="h-16 text-center flex justify-center pt-4">
			<div class="pl-8 text-left text-lg  relative">
				<!-- cart icon -->
				<i class="fas fa-shopping-cart text-2xl"></i>
				<div
					class="text-center absolute bg-green-500 text-white w-5 h-5 text-xs p-0 leading-5 rounded-full -right-2 top-3"
				>
					{{ sumQuantity }}
				</div>
			</div>
			<div class="flex-grow px-8 text-right text-lg  relative">
				<!-- trash button -->
				<button
					@click="clearCart()"
					class="px-2 py-1 rounded-md text-white border-white border border-transparent bg-red-500 focus:outline-none text-md hover:bg-red-700"
				>
					<i class="far fa-trash-alt"></i>
				</button>
			</div>
		</div>
		<!-- empty cart -->
		<div
			v-if="carts.length == 0"
			class="flex-1 w-full p-4 opacity-25 select-none flex flex-col flex-wrap content-center justify-center"
		>
			<i class="fas fa-shopping-cart text-4xl text-center"></i>
			<p>
				CART EMPTY
			</p>
		</div>

		<!-- cart items -->
		<div v-if="carts.length > 0" class="flex-1 flex flex-col overflow-auto">
			<div class="flex-1 w-full px-4 overflow-auto">
				<div
					v-for="cart in carts.slice().reverse()"
					:key="cart.id"
					class="select-none mb-3 bg-gray-50 rounded-lg w-full text-gray-700 py-2 px-2 flex justify-center"
				>
					<img
						@click="addDiscount(cart)"
						:src="cart.photo"
						alt=""
						class="rounded-lg h-10 w-10 bg-white shadow mr-2 cursor-pointer hover:text-blue-500"
					/>
					<div @click="addDiscount(cart)" class="flex-grow cursor-pointer  hover:text-blue-500">
						<h5 class="text-sm">{{ cart.name }}</h5>
						<p class="text-xs block" v-if="cart.serial_status">S/N: {{ cart.serial }}</p>
						<p class="text-xs block" v-if="!cart.serial_status">Shelf: {{ cart.shelved_address }}</p>
						<p class="text-xs block">
							<span v-if="cart.discount_baht == 0"
								>฿ {{ useFormatNumber(cart.price * cart.quantity) }}</span
							>
							<span v-if="cart.discount_baht > 0"
								>฿ {{ useFormatNumber(cart.price * cart.quantity - cart.discount_baht) }} (
								{{ useFormatNumber(cart.price * cart.quantity) }} )</span
							>
						</p>
					</div>
					<div class="py-1">
						<div class="w-28 grid grid-cols-3 gap-2 items-center">
							<button
								@click="subQty(cart, 1)"
								class="rounded-lg text-center py-1 text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
							>
								-
							</button>
							<p class="text-center flex justify-center">{{ cart.quantity }}</p>
							<button
								@click="addQty(cart, 1)"
								class="rounded-lg text-center py-1 text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
							>
								+
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end of cart items -->

		<!-- payment info -->
		<div class="select-none h-auto w-full text-center pt-3 pb-4 px-4">
			<div class="mb-3 text-gray-700 px-3 pt-2 pb-3 rounded-lg bg-green-50">
				<div class="flex text-lg font-semibold">
					<div @click="addCash" class="flex-grow text-left hover:text-blue-300 cursor-pointer">TOTAL</div>
					<div class="flex text-right">
						<div v-if="netPrice == sumPrice" class="text-right w-full">
							{{ useFormatNumber(sumPrice) }}
						</div>
						<div v-if="netPrice != sumPrice" class="text-right w-full">
							{{ useFormatNumber(netPrice) }} ( {{ useFormatNumber(sumPrice) }})
						</div>
					</div>
				</div>
			</div>
			<div class="mb-3 text-gray-700 px-3 pt-2 pb-3 rounded-lg bg-yellow-50">
				<div class="flex text-lg font-semibold">
					<div class="flex-grow text-left">DISCOUNT</div>
					<div class="flex text-right">
						<p class="">~ {{ useFormatNumber(sumDiscount) }}</p>
					</div>
				</div>
			</div>

			<div class="flex w-full mb-2 ">
						<button
							:class="[
								'w-1/2 py-1  text-lg text-white rounded-l-lg focus:outline-none ',
								selectedReceiptOption === 'Tax' ? 'bg-blue-500 border-2 border-blue-400 border-solid' : 'bg-gray-300',
							]"
							@click="onReceiptTypeChange('Tax')"
						>
							ใบกำกับภาษีอย่างย่อ
						</button>
						<button
							:class="[
								'w-1/2 py-1 text-lg text-white rounded-r-lg focus:outline-none',
								selectedReceiptOption === 'Non-tax' ? 'bg-blue-500 border-2 border-blue-400 border-solid' : 'bg-gray-300',
							]"
							@click="onReceiptTypeChange('Non-tax')"
						>
							ใบรับเงินชั่วคราว
						</button>
					</div>
			<div class="flex justify-around">
				<button
					@click="save(2)"
					class="text-white rounded-2xl text-lg py-2 mr-2 w-full focus:outline-none"
					:class="carts.length > 0 ? 'bg-green-400 hover:bg-green-500' : 'bg-gray-300 cursor-not-allowed'"
					:disabled="carts.length == 0"
				>
					Payment <br />
					<span class="text-xs">{{ order_no }}</span>
				</button>
				<button
					@click="save(1)"
					class="text-white rounded-2xl text-lg w-4/12 py-2 focus:outline-none"
					:class="carts.length > 0 ? 'bg-yellow-400 hover:bg-yellow-500' : 'bg-gray-300 cursor-not-allowed'"
					:disabled="carts.length == 0"
				>
					Pending
				</button>
			</div>
		</div>
		<!-- end of payment info -->
	</div>
</template>

<script>
import { computed } from 'vue';
import { useFormatNumber } from '@/js/format';
export default {
	name: 'Cart',
	props: [
		'order_no',
		'carts',
		'clearCart',
		'addDiscount',
		'subQty',
		'addQty',
		'netPrice',
		'sumPrice',
		'sumDiscount',
		'save',
		'selectedReceiptOption',
		'onReceiptTypeChange'
	],
	setup(props) {
		const sumQuantity = computed(() => {
			return props['carts'].reduce((accumulator, item) => accumulator + item.quantity, 0);
		});
		return {
			sumQuantity,
			useFormatNumber,
		};
	},
};
</script>
