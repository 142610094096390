<template>
  <div class="bg-gray-100 h-screen">
    <div class="flex flex-row h-screen antialiased text-blue-gray-800">
      <div class="flex flex-row w-auto flex-shrink-0 pl-4 pr-2 py-4">
          <SideBar />
      </div>
      <div class="flex-grow flex flex-col">
          <div class="text-right text-sm mr-5 mb-1">
            Username : {{ username }},
            POS ID : {{ pos_machine }},
            POS No : {{ pos_no }},
            Warehouse : {{ warehouse_location }}
          </div>
          <div class="flex-grow flex pb-8 h-screen">
            <router-view />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
  name: "Main",
  components: {
    SideBar,
  },
  setup() {
      const username = localStorage.getItem('username')
      const pos_machine = localStorage.getItem('pos_machine')
      const pos_no = localStorage.getItem('pos_no')
      const warehouse_location = localStorage.getItem('warehouse_location')

      return { username, pos_machine, pos_no, warehouse_location }
  },
};
</script>

<style></style>
