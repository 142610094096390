<template>
    <div class="flex flex-col items-center justify-between py-4 flex-shrink-0 w-20 rounded-3xl" :class="backend_url == 'https://service.test.thedigitalstm.com/' ? 'bg-red-500' :'bg-green-400' ">
        <div>
          <a href="/" class="flex items-center justify-center h-12 w-12 bg-black rounded-xl">
            <img src="../assets/image/stmlogo.png" alt="">
          </a>
          <ul class="flex flex-col space-y-2 mt-12">
            <li>
              <a href="/"  class="flex items-center" :class="this.$router.currentRoute.value.path==='/' ? 'h-12 w-12 rounded-2xl bg-green-800 shadow-lg' : ''">
                <span class="flex items-center justify-center text-white hover:bg-green-600 h-12 w-12 rounded-2xl">
                  <em class="fas fa-calculator"></em>
                </span>
              </a>
            </li>
            <li>
              <router-link to="/orders"  class="flex items-center" active-class="h-12 w-12 rounded-2xl bg-green-800 shadow-lg">
                <span class="flex items-center justify-center text-white hover:bg-green-600 h-12 w-12 rounded-2xl">
                  <em class="fas fa-shopping-cart"></em>
                </span>
              </router-link>
            </li>
            <li>
              <router-link to="/invoices"  class="flex items-center" active-class="h-12 w-12 rounded-2xl bg-green-800 shadow-lg">
                <span class="flex items-center justify-center text-white hover:bg-green-600 h-12 w-12 rounded-2xl">
                  <em class="fas fa-file-invoice-dollar"></em>
                </span>
              </router-link>
            </li>
            <li v-if="pos_warehouse_location_id==1">
              <router-link to="/receipts"  class="flex items-center" active-class="h-12 w-12 rounded-2xl bg-green-800 shadow-lg">
                <span class="flex items-center justify-center text-white hover:bg-green-600 h-12 w-12 rounded-2xl">
                  <strong>R</strong>
                </span>
              </router-link>
            </li>
            <li>
              <router-link to="/customers" class="flex items-center" active-class="h-12 w-12 rounded-2xl bg-green-800 shadow-lg">
                <span class="flex items-center justify-center text-white hover:bg-green-600 h-12 w-12 rounded-2xl">
                  <em class="fas fa-users"></em>
                </span>
              </router-link>
            </li>
            <li>
              <router-link to="/setting" class="flex items-center" active-class="h-12 w-12 rounded-2xl bg-green-800 shadow-lg">
                <span class="flex items-center justify-center text-white hover:bg-green-600 h-12 w-12 rounded-2xl">
                  <em class="fas fa-cog"></em>
                </span>
              </router-link>
            </li>
            <li>
              <button @click="logout" class="flex items-center" active-class="h-12 w-12 rounded-2xl bg-green-800 shadow-lg">
                <span class="flex items-center justify-center text-white hover:bg-red-500 h-12 w-12 rounded-2xl">
                  <em class="fas fa-sign-out-alt"></em>
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div class="text-sm text-center">
          Version <br>{{ version }}
        </div>
    </div>

    
</template>

<script>
import { useRouter } from "vue-router";
import { version } from "../../package.json";

export default {
    name : "SideBar",
    setup() {
        const router = useRouter();
        const backend_url = process.env.VUE_APP_URL;
        const pos_warehouse_location_id = localStorage.getItem('pos_warehouse_location_id');
        console.log(router.currentRoute.value.path);

        function logout(){
          localStorage.removeItem('user')
          router.push("/login");
        }
        return { logout, backend_url, pos_warehouse_location_id, version }
    },
}
</script>
