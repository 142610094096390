<template>
	<div class="flex flex-col w-full px-5 mx-2 bg-white rounded-2xl pb-2">
		<div class="flex items-center justify-between mt-5 mb-2">
			<h2 class="my-6 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">Orders ({{ total }})</h2>
			<div class="flex justify-center flex-1 lg:mr-32">
				<div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
					<div class="absolute inset-y-0 flex items-center pl-2">
						<i class="fas fa-search"></i>
					</div>
					<form @submit.prevent="submitSearchForm">
						<input
							v-model="keyword"
							class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md"
							type="text"
							placeholder="Search"
							aria-label="Search"
						/>
					</form>
				</div>
				<div>
					<button
						@click="submitSearchForm"
						class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple"
					>
						<i class="fas fa-search mr-2"></i>
						<span>Search</span>
					</button>
				</div>
				<div>
					<button
						@click="resetSearchForm"
						class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-red-500 transition-colors duration-150 border-red-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-purple"
					>
						<i class="fas fa-sync mr-2"></i>
						<span>Clear</span>
					</button>
				</div>
			</div>
		</div>

		<div class="border-b border-gray-200 dark:border-gray-700">
			<ul
				class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 justify-between"
			>
				<li class="flex-1">
					<button
						@click="handleBillTypeChange('all')"
						:class="
							bill_type === 'all'
								? 'text-blue-600 border-blue-600 bg-gray-300 '
								: 'text-blue-500 hover:text-blue-500 border-gray-200 hover:bg-gray-50 hover:border-blue-600'
						"
						class="w-full inline-flex items-center justify-center border pt-4 px-4 pb-2 border-b-2 border-transparent rounded-t-lg dark:hover:text-gray-300 focus:outline-none group text-sm font-bold"
					>
						<i class="fas fa-clipboard-list mr-2 text-sm font-bold"></i>ทั้งหมด
					</button>
				</li>
				<li class="flex-1">
					<button
						@click="handleBillTypeChange('Tax')"
						:class="
							bill_type === 'Tax'
								? 'text-yellow-600 border-yellow-600 bg-gray-300'
								: 'text-yellow-500 hover:text-yellow-500 border-gray-200 hover:bg-gray-50 hover:border-yellow-600'
						"
						class="w-full inline-flex items-center justify-center border pt-4 px-4 pb-2 border-b-2 border-transparent rounded-t-lg dark:hover:text-gray-300 focus:outline-none group text-sm font-bold"
					>
						<i class="fas fa-receipt mr-2 text-sm font-bold"></i>ใบกำกับภาษีอย่างย่อ
					</button>
				</li>
				<li class="flex-1">
					<button
						@click="handleBillTypeChange('Non-tax')"
						:class="
							bill_type === 'Non-tax'
								? 'text-green-600 border-green-600 bg-gray-300'
								: 'text-green-500 hover:text-green-500 border-gray-200 hover:bg-gray-50 hover:border-green-600'
						"
						class="w-full inline-flex items-center justify-center border pt-4 px-4 pb-2 border-b-2 border-transparent rounded-t-lg dark:hover:text-gray-300 focus:outline-none group text-sm font-bold"
					>
						<i class="fas fa-file-invoice-dollar mr-2 text-sm font-bold"></i>ใบรับเงินชั่วคราว
					</button>
				</li>
			</ul>
		</div>

		<div class="w-full overflow-hidden rounded-lg shadow-sm">
			<div class="h-full overflow-y-auto">
				<table class="w-full whitespace-no-wrap">
					<thead>
						<tr
							class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800"
						>
							<th class="px-4 py-3">#</th>
							<th class="px-4 py-3">Order No.</th>
							<th class="py-3">Customer</th>
							<th class="pr-2 py-3 text-right">Total Price</th>
							<th class="px-4 py-3 text-center">Status</th>
							<th class="px-4 py-3"></th>
							<!-- <th class="px-4 py-3"></th> -->
						</tr>
					</thead>
					<tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
						<tr
							v-for="(order, index) in orders"
							:key="order.id"
							class="text-gray-700 dark:text-gray-400 hover:bg-blue-100"
						>
							<td
								class="px-4 py-3 text-sm"
								:class="order.deleted_at != null ? 'line-through text-red-500' : ''"
							>
								{{ (current_page - 1) * per_page + (index + 1) }}
							</td>
							<td class="px-4 py-3" :class="order.deleted_at != null ? 'line-through text-red-500' : ''">
								<div class="flex items-center text-sm">
									<div>
										<p class="font-semibold">{{ order.order_no }}</p>
										<p class="text-xs">Created {{ formatDate(order.created_at) }}</p>
									</div>
								</div>
							</td>
							<td class="py-3 text-sm">
								<button
									v-if="!order.pos_order_invoice && order.deleted_at == null && order.bill_type === 'Tax'"
									@click="openModalTax(order.order_no, order.pos_order_status_id)"
									class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-plus"></i> Invoice
								</button>
								<p v-if="order.pos_order_invoice">
									{{ order.pos_order_invoice.pos_customer.full_name }}
									<span v-if="!order.is_complete">
										<button
											@click="openModalTax(order.order_no, order.pos_order_status_id)"
											class="px-2 py-1 mx-1 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple"
										>
											<i class="fas fa-edit"></i>
										</button>
										<button
											@click="deleteTax(order.pos_order_invoice.tax_invoice_no)"
											class="px-2 py-1 mx-1 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
										>
											<i class="fas fa-times"></i>
										</button>
									</span>
									<span v-if="order.is_complete">
										<button
											class="px-2 py-1 mx-1 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-yellow-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple"
										>
											<i class="fas fa-edit"></i>
										</button>
										<button
											class="px-2 py-1 mx-1 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple"
										>
											<i class="fas fa-times"></i>
										</button>
									</span>
								</p>
							</td>
							<td
								class="pr-2 py-3 text-sm text-right"
								:class="order.deleted_at != null ? 'line-through text-red-500' : ''"
							>
								<p class="">{{ formatNumber(order.grand_total) }}</p>
								<p class="text-xs">
									{{ order.pos_order_payments.map(item => item.payment_type).join(', ') }}
								</p>
							</td>
							<td class="px-4 py-3 text-sm text-center">
								<span
									v-if="order.deleted_at == null"
									class=" py-1 px-3 rounded-full text-xs"
									:class="
										order.pos_order_status_id == 1
											? 'bg-yellow-200 text-yellow-600'
											: 'bg-green-200 text-green-600'
									"
									>{{ order.pos_order_status.name }}</span
								>
								<span
									v-if="order.deleted_at != null"
									class=" py-1 px-3 rounded-full text-xs bg-red-200 text-red-500"
									>Cancel</span
								><br />
								<span
									v-if="order.is_order_management === 1"
									class="mt-1 py-1 px-3 rounded-full text-xs bg-blue-200 text-blue-500"
									>Order management</span
								>
								<p class="text-xs mt-1">{{ order.bill_type === 'Tax' ? 'ใบกำกับภาษีอย่างย่อ' : 'ใบรับเงินชั่วคราว' }}</p>
							</td>
							<td class="px-4 py-3 text-sm text-center">
								<button
									v-if="order.pos_order_status_id === 2 || order.deleted_at != null"
									@click="openModalReceipt(order.order_no)"
									class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-print"></i>
								</button>
								<button
									v-else-if="order.pos_order_status_id === 1"
									class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-200 border border-transparent rounded-lg active:bg-purple-600 cursor-not-allowed focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-print"></i>
								</button>
								<!-- BTN Tax invoice start -->
								<button
									v-if="order.pos_order_invoice"
									@click="printTaxInvoice(order.pos_order_invoice.tax_invoice_no)"
									class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-file-invoice-dollar"></i>
								</button>
								<button
									v-if="!order.pos_order_invoice"
									class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-file-invoice-dollar"></i>
								</button>
								<!-- BTN Tax invoice end -->

								<!-- BTN Edit start -->
								<button
									v-if="!order.pos_order_invoice && order.deleted_at == null"
									@click="$router.push('/?order_no_query=' + order.order_no)"
									class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="far fa-edit"></i>
								</button>
								<button
									v-if="order.pos_order_invoice || order.deleted_at != null"
									class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-edit"></i>
								</button>
								<!-- BTN Edit start -->

								<!-- BTN Delete start -->
								<button
									v-if="!order.pos_order_invoice && order.deleted_at == null"
									@click="deleteOrder(order.order_no)"
									class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
								>
									<i class="far fa-trash-alt"></i>
								</button>
								<button
									v-if="order.pos_order_invoice || order.deleted_at != null"
									class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple"
								>
									<i class="fas fa-trash-alt"></i>
								</button>
								<!-- BTN Delete start -->
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<VueTailwindPagination
			:current="current_page"
			:total="total"
			:per-page="per_page"
			@page-changed="onPageClick($event)"
		/>
	</div>

	<!--Modal Receipt-->
	<div
		v-if="modal_receipt"
		class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center overflow-hidden"
		@contextmenu="stop_click_right($event)"
	>
		<div
			@click="modal_receipt = false"
			class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"
		></div>

		<div
			class="modal-container bg-white lg:w-1/3 w-1/2 mx-auto rounded-2xl shadow-lg z-50 max-h-full overflow-y-auto"
		>
			<div class="">
				<div
					@click="modal_receipt = false"
					class="modal-close cursor-pointer z-50 float-right mr-3 mt-2 text-black"
				>
					<i class="fas fa-times"></i>
				</div>
				<!--Body-->
				<Receipt :order_no="order_no" />

				<div class="p-4 w-full flex justify-center">
					<button
						@click="printReceipt"
						class="text-white bg-green-400 hover:bg-green-600 text-lg py-2 rounded-2xl w-full focus:outline-none mx-2"
					>
						<i class="fas fa-print mr-2"></i>Receipt
					</button>
				</div>
			</div>
		</div>
	</div>
	<!--Modal Receipt-->
	<!--Modal Tax-->
	<span v-if="modal_form_tax">
		<TaxModal
			:modal_form_tax_props="modal_form_tax"
			:order_no_props="order_no"
			@closeModalTax="modal_form_tax = false"
			@saveTax="saveTax"
		/>
	</span>
	<!--Modal Tax-->
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { printTaxInvoice } from '@/js/pos_order_invoice';
import Receipt from '@/components/Receipt.vue';
import TaxModal from '@/components/TaxModal.vue';
import api from '@/services/auth';
import { useSweetalertTopEnd, useSweetalertError } from '@/js/sweetalert';
import '@ocrv/vue-tailwind-pagination/dist/style.css';
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination';
import { useFormatNumber, useFormatDate } from '@/js/format';
import Swal from 'sweetalert2';
import { alert_is_order_management } from '@/js/pos_order';

export default {
	name: 'Order',
	components: {
		Receipt,
		TaxModal,
		VueTailwindPagination,
	},
	setup() {
		const orders = ref([]);
		const keyword = ref('');
		const modal_receipt = ref(false);
		const order_no = ref('');
		const customer_id = ref('');
		const total = ref(0);
		const current_page = ref(1);
		const per_page = ref(0);
		const formatNumber = useFormatNumber;
		const formatDate = useFormatDate;
		const modal_form_tax = ref(false);
		const warehouse_location_id = localStorage.getItem('warehouse_location_id');
		const bill_type = ref('all');

		const getPosOrders = (page, keyword_param) => {
			const params =
				bill_type.value === 'all'
					? {
							page,
							keyword: keyword_param,
							warehouse_location_id,
					  }
					: {
							page,
							keyword: keyword_param,
							warehouse_location_id,
							bill_type: bill_type.value,
					  };

			api.get('api/pos_orders', { params: params })
				.then(response => {
					let success_response = response.data;
					orders.value = success_response.data.data;
					total.value = success_response.data.total;
					current_page.value = success_response.data.current_page;
					per_page.value = success_response.data.per_page;
				})
				.catch(error => {
					let error_response = error.response.data;
					useSweetalertTopEnd(1500, 'error', error_response.message);
				});
		};

		onMounted(() => {
			getPosOrders(current_page.value, keyword.value);
		});

		function onPageClick(event) {
			current_page.value = event;

			getPosOrders(current_page.value, keyword.value);
		}
		function openModalReceipt(order_no_param) {
			modal_receipt.value = true;
			order_no.value = order_no_param;
		}

		function printReceipt() {
			const order = orders.value.find(item => item.order_no == order_no.value);
			if (order.is_order_management === 1) {
				axios
					.post(`${process.env.VUE_APP_HOST_ORDER_MANAGEMENT}orders/print_short_tax_invoice`, {
						erp_pos_order_id: order.id,
						obj_pos_order: order,
					})
					.then(response => {
						console.log(response);
						window.open(`orders/${order_no.value}`);
						modal_receipt.value = false;
					})
					.catch(error => {
						let error_response = error.response.data;
						useSweetalertError('Error from order management' + error_response.error_message);
					});
			} else {
				window.open(`orders/${order_no.value}`);
				modal_receipt.value = false;
			}
		}
		function submitSearchForm() {
			console.log(keyword.value);
			getPosOrders(1, keyword.value);
		}
		function resetSearchForm() {
			keyword.value = '';
			getPosOrders(1, keyword.value);
		}

		function deleteOrder(order_no_param) {
			if (orders.value.find(item => item.order_no === order_no_param).is_order_management === 1) {
				alert_is_order_management();
				return false;
			}
			Swal.fire({
				title: 'Do you want to delete?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
			}).then(result => {
				if (result.isConfirmed) {
					api.delete(`api/pos_orders/${order_no_param}`)
						.then(() => {
							getPosOrders(current_page.value, keyword.value);
						})
						.catch(error => {
							let error_response = error.response.data;
							useSweetalertTopEnd(1500, 'error', error_response.message);
						});
				}
			});
		}
		function openModalTax(order_no_param, status_id) {
			//   if(orders.value.find(item => item.order_no===order_no_param).is_order_management===1){
			//         alert_is_order_management()
			//         return false;
			//     }
			if (status_id == 1) {
				Swal.fire({
					icon: 'error',
					text: `You can't create invoice on order status Pending`,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK',
				});
			} else if (status_id == 2) {
				modal_form_tax.value = true;
				order_no.value = order_no_param;
			}
		}
		function deleteTax(tax_invoice_no_param) {
			Swal.fire({
				title: 'Do you want to void?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, void it!',
			}).then(result => {
				if (result.isConfirmed) {
					api.delete(`api/pos_order_invoices/${tax_invoice_no_param}`)
						.then(() => {
							getPosOrders(current_page.value, keyword.value);
						})
						.catch(error => {
							let error_response = error.response.data;
							useSweetalertError(error_response.message);
						});
				}
			});
		}
		function onSelectCustomer(event) {
			customer_id.value = event;
		}

		function saveTax() {
			getPosOrders(current_page.value, keyword.value);
		}

		function stop_click_right(e) {
			e.preventDefault();
		}

		function handleBillTypeChange(status) {
			bill_type.value = status;
			getPosOrders(1, keyword.value);
		}

		return {
			orders,
			order_no,
			total,
			current_page,
			per_page,
			formatNumber,
			formatDate,
			onPageClick,
			modal_receipt,
			openModalReceipt,
			customer_id,
			saveTax,
			printReceipt,
			printTaxInvoice,
			submitSearchForm,
			keyword,
			resetSearchForm,
			deleteOrder,
			openModalTax,
			deleteTax,
			onSelectCustomer,
			modal_form_tax,
			stop_click_right,
			handleBillTypeChange,
            bill_type
		};
	},
};
</script>
