<template>
	<div class="h-full overflow-y-auto px-2">
		<div
			v-if="products.length == 0"
			class="select-none bg-gray-100 rounded-3xl flex flex-wrap content-center justify-center h-full opacity-25"
		>
			<div class="w-full text-center">
				<i class="fas fa-search text-4xl"></i>
				<p class="text-xl">
					EMPTY SEARCH RESULT
					<br />
					<!-- "<span class="font-semibold">{{ keyword_result }}</span>" -->
				</p>
			</div>
		</div>
		<div v-if="products.length > 0" class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 pb-3">
			<div
				v-for="product in products"
				:key="product"
				@click="addToCart(product)"
				role="button"
				class="flex flex-col justify-between select-none cursor-pointer transition-shadow overflow-hidden rounded-2xl bg-white shadow hover:shadow-xl"
			>
				<img :src="product.photo" />
				<div class="flex flex-col px-3 text-xs lg:text-sm mb-2 mt-1">
					<p class="flex-grow truncate mr-1">{{ product.name }}</p>
					<p class="flex-grow truncate mr-1">S/N: {{ product.serial }}</p>
					<p class="nowrap font-semibold">฿{{ useFormatNumber(product.price) }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useFormatNumber } from '@/js/format';
export default {
	name: 'Products',
	props: ['products', 'addToCart', 'keyword'],
	setup() {
		return {
			useFormatNumber,
		};
	},
};
</script>
