<template>
    <div class="flex flex-col w-full px-5 mx-2 bg-white rounded-2xl pb-2">
      <div class="flex items-center justify-between mt-5 mb-2">
          <h2 class="my-6 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
              Receipt ({{ total }})
          </h2>
          <div class="flex justify-center flex-1 lg:mr-32">
              <div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
                  <div class="absolute inset-y-0 flex items-center pl-2">
                      <em class="fas fa-search"></em>
                  </div>
                  <form @submit.prevent="submitSearchForm">
                      <input
                          v-model="keyword"
                          class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md"
                          type="text"
                          placeholder="Search"
                          aria-label="Search"
                      />
                  </form>
              </div>
              <div>
                  <button @click="submitSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                      <em class="fas fa-search mr-2"></em>
                      <span>Search</span>
                  </button>
              </div>
              <div>
                  <button @click="resetSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-red-500 transition-colors duration-150 border-red-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-purple">
                      <em class="fas fa-sync mr-2"></em>
                      <span>Clear</span>
                  </button>
              </div>
          </div>
      </div>

      <div class="w-full overflow-hidden rounded-lg shadow-sm">
          <div class="h-full overflow-y-auto">
              <table class="w-full whitespace-no-wrap">
                  <thead>
                      <tr class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                          <th class="px-4 py-3">#</th>
                          <th class="px-4 py-3">Receipt Date</th>
                          <th class="px-4 py-3">Receipt No.</th>
                          <th class="px-4 py-3">Reference</th>
                          <th class="py-3">Customer</th>
                          <th class="pr-2 py-3 text-right">Total Price</th>
                          <th class="px-4 py-3"></th>
                      </tr>
                  </thead>
                  <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
      
                      <tr v-for="(receipt, index) in receipts" :key="receipt.id" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100" :class="receipt.deleted_at!=null ? 'line-through text-red-500': ''">
                          <td class="px-4 py-3 text-sm">{{ (current_page-1) * per_page + (index+1) }}</td>
                          <td class="px-4 py-3 text-sm">{{ formatDate(receipt.document_date) }}</td>
                          <td class="px-4 py-3 text-sm">{{ receipt.document_no }}</td>
                          <td class="py-3 text-sm">{{ receipt.pos_order_invoice.tax_invoice_no }}</td>
                          <td class="py-3 text-sm">{{ receipt.pos_order_invoice.pos_customer.full_name }}</td>
                          <td class="pr-2 py-3 text-sm text-right">{{ formatNumber(receipt.grand_total) }}</td>
                          <td class="px-4 py-3 text-sm text-center">
                            <!-- BTN Receipt receipt start -->
                            <button @click="printReceipt(receipt.document_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple">
                                  <em class="fas fa-receipt"></em>
                            </button>
                            <!-- BTN Receipt receipt end -->

                            <!-- BTN Edit start -->
                            <button v-if="receipt.pos_status==1 && receipt.deleted_at==null" @click="openModalReceipt(receipt.pos_order_invoice.tax_invoice_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple">
                                <em class="far fa-edit"></em>
                            </button>
                            <button v-if="receipt.pos_status!=1 || receipt.deleted_at!=null" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple">
                                <em class="fas fa-edit"></em>
                            </button>
                            <!-- BTN Edit start -->

                            <!-- BTN Delete start -->
                            <button v-if="receipt.pos_status==1 && receipt.deleted_at==null" @click="deleteReceipt(receipt.document_no)" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
                                <em class="fas fa-times"></em>
                            </button>
                            <button v-if="receipt.pos_status!=1 || receipt.deleted_at!=null" class="px-4 py-2 mx-1 mt-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-200 border border-transparent rounded-lg cursor-not-allowed active:bg-purple-600  focus:outline-none focus:shadow-outline-purple">
                                <em class="fas fa-times"></em>
                            </button>
                            <!-- BTN Delete start -->
                          </td>
                          
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <VueTailwindPagination
            :current="current_page"
            :total="total"
            :per-page="per_page"       
            @page-changed="getReceipt($event)"         
        />
    </div>

    <!--Modal Receipt-->
    <span v-if="modal_form_receipt">
        <ReceiptModal :modal_form_receipt_props="modal_form_receipt" :tax_invoice_no_props="tax_invoice_no" @closeModalReceipt="modal_form_receipt=false" @saveReceipt="saveReceipt"/>
    </span>
    <!--Modal Receipt-->
    
</template>

<script>
import { ref, onMounted } from "vue";
import api from '@/services/auth'
import { useModalReceipt, printReceipt } from '@/js/receipt'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import "@ocrv/vue-tailwind-pagination/dist/style.css"
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
import { useFormatNumber, useFormatDate } from '@/js/format'
import ReceiptModal from '@/components/ReceiptModal.vue'
import Swal from 'sweetalert2'

export default {
    name:"Order",
    components:{
        VueTailwindPagination,
        ReceiptModal
    },
    setup(){
        const receipts = ref([]);
        const keyword = ref("");
        const { tax_invoice_no, modal_form_receipt, openModalReceipt } = useModalReceipt();
        const total = ref(0);
        const current_page = ref(1);
        const per_page = ref(0);
        const formatNumber = useFormatNumber;
        const formatDate = useFormatDate;

        const getReceipt = (page) => { 
            api.get(`api/receipts?page=${page}&keyword=${keyword.value}&type=pos`).then((response)=>{
                let success_response = response.data;
                receipts.value = success_response.data.data
                total.value = success_response.data.total
                per_page.value = success_response.data.per_page
                current_page.value = success_response.data.current_page
            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertTopEnd(1500, 'error', error_response.message)
            });
        }

        onMounted(() => {
            getReceipt(1);
        });


        function submitSearchForm(){
            current_page.value = 1
            getReceipt(current_page.value);
        }
        function resetSearchForm(){
            current_page.value = 1
            keyword.value = ""
            getReceipt(current_page.value);
        }

        function deleteReceipt(receipt_no_param){
            Swal.fire({
                title: 'Do you want to void receipt '+receipt_no_param+' ?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, void it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    api.delete(`api/receipts/${receipt_no_param}`).then(()=>{
                        getReceipt(current_page.value);
                    }).catch( error => {
                        let error_response = error.response.data;
                        useSweetalertTopEnd(1500, 'error', error_response.message)
                    });
                }
            })
        }
        function saveReceipt(){
            getReceipt(current_page.value);
        }


        return { 
            getReceipt,
            receipts, 
            keyword,
            tax_invoice_no,
            modal_form_receipt,
            openModalReceipt,
            total,
            current_page,
            per_page,
            formatNumber,
            formatDate,
            printReceipt,
            submitSearchForm,
            resetSearchForm,
            deleteReceipt,
            saveReceipt
        }
    }
  
}
</script>