<template>
    <div v-if="loading" id="receipt-content" class="text-left w-full text-sm p-6 pt-2 print-area receipt">
      <ReceiptContent
        :order="order"
        :total_product="total_product"
        :company="company"
        :payments="payments"
        :online_order_no="online_order_no"
        :index="0"
      />
    </div>
</template>

<script>
import { onMounted } from "vue";
import { useGetOrderByOrderNo } from '@/js/pos_order'
import { useCompany } from '@/js/company'
import moment from 'moment'
import ReceiptContent from "./receipt/ReceiptContent.vue";

export default {
  name: 'Receipt',
  components: {
    ReceiptContent
  },
  props:[
      'order_no',
  ],
  setup(props){
    const { order, total_product, getOrderByOrderNo, payments, online_order_no, loading } = useGetOrderByOrderNo();
    const { company, getCompanyById } = useCompany();

    onMounted(() => {
        getOrderByOrderNo(props['order_no']);
        getCompanyById(1)
    });

    return { order, loading, company, moment, total_product, payments, online_order_no }
  }
}
</script>
<style scope>
@page {
    margin: 0;
    size: auto;
}
@font-face {
  font-family: myFirstFont;
  src: url(../assets/font/f25_bank_printer/F25_Bank_Printer_Bold.ttf);
}
@font-face {
  font-family: myFirstFont2;
  src: url(../assets/font/kravitz/test.TTF);
}
@font-face {
  font-family: TahomaFont;
  src: url(../assets/font/Tahoma-Font/TAHOMA_0.TTF);
}
#receipt-content{
   font-family: myFirstFont;
}

</style>