import {
	createStore
} from 'vuex'
import payments from './modules/payments'
import customers from './modules/customers'

export default createStore({
	state: {
		pos_machine_id: null,
		isLoading: false,
	},
	mutations: {
		setPosId(state, n) {
			state.pos_machine_id = n
		},
		setLoading(state, isLoading) {
			state.isLoading = isLoading
		}
	},
	actions: {},
	modules: {
		payments,
		customers,
	}
})